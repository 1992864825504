import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './container/index';
import * as serviceWorker from './serviceWorker';

//notification
// import { requestFirebaseNotificationPermission } from './firebaseInit'
import { registerServiceWorker } from './serviceWorker'

registerServiceWorker()

// requestFirebaseNotificationPermission()
//     .then((firebaseToken) => {
//         // eslint-disable-next-line no-console
//         console.log(firebaseToken);
//     })
//     .catch((err) => {
//         return err;
//     });

ReactDOM.render(<App />, document.getElementById('root'))

// serviceWorker.unregister();