export const userConstants = {

    USERLOGIN_REQUEST: 'USERLOGIN_REQUEST',
    USERLOGIN_SUCCESS: 'USERLOGIN_SUCCESS',
    USERLOGIN_FAILURE: 'USERLOGIN_FAILURE',

    STORE_CURRENT_USER: 'STORE_CURRENT_USER',
    STORE_CURRENT_USER_SETTINGS: 'STORE_CURRENT_USER_SETTINGS',

    GET_CURRENT_USER_FROM_STORE: 'GET_CURRENT_USER_FROM_STORE',

    UPDATE_TAB_DATA: 'UPDATE_TAB_DATA',

}
