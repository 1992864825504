import { gameConstants } from '../constants'


export const playerLogin = (islogin) => {
    return {
        type: gameConstants.PLAYER_LOGIN,
        isLogin: islogin,
    }
}


export const createNewGame = (gameData) => {
    return {
        type: gameConstants.CREATE_NEW_GAME,
        gameData: gameData,
    }
}

export const updateCurrentPlayerID = (playerid) => {
    return {
        type: gameConstants.UPDATE_CURRENT_PLAYER_ID,
        playerID: playerid,
    }
}

export const removeCurrentPlayerID = (playerid) => {
    return {
        type: gameConstants.REMOVE_CURRENT_PLAYER_ID,
        playerID: playerid,
    }
}

export const storeCurrentGameDetails = (gameData) => {
    return {
        type: gameConstants.STORE_CURRENT_GAME_DETAILS,
        gameData: gameData,
    }
}

export const storeCurrentPlayerCredentials = (credentials) => {
    return {
        type: gameConstants.STORE_CURRENT_PLAYER_CREDENTIALS,
        credentials: credentials,
    }
}

export const setTabToStore = (data) => {
    return {
        type: gameConstants.SET_TAB_TO_STORE,
        tabData: data,
    }
}

export const setGamePlayerToStore = (data) => {
    return {
        type: gameConstants.SET_GAME_PLAYER,
        playerData: data,
    }
}

export const setNotificationToStore = (data) => {
    return {
        type: gameConstants.SET_NOTIFICATION_TO_STORE,
        notificationData: data,
    }
}



