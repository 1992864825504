import React, { Component } from 'react';
import bannerImg from '../assets/images/banner-bg.svg'
import Header from './Header';
import Footer from './Footer';

import request from 'superagent';
import { connect } from 'react-redux'


export default class RoleDescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roleNameList: [],
            roleCardList: []
        }
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    componentWillMount() {
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        if (teamLoginDetails != null) {
            this.loadRoleNames(teamLoginDetails.organisationId)
            this.loadRoleCards(teamLoginDetails.organisationId)
        } else {

        }
    }

    async loadRoleNames(orgID) {
        const r = await request.post(`${this.apiBase}/getRoleName`)
            .send({
                organisationId: orgID
            })
        const response = r.body;
        if (response.success) {
            this.setState({
                roleNameList: response.ResponsePacket
            })
        }
    }

    async loadRoleCards(orgID) {
        const r = await request.post(`${this.apiBase}/getRoleCard`)
            .send({
                organisationId: orgID
            })
        const response = r.body;
        if (response.success) {
            this.setState({
                roleCardList: response.ResponsePacket
            })
        }
    }

    render() {
        return (
            <div className="mainOuterDiv">
                <Header childProps={this.props} />
                <section className="match-section set-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h3><span> Role Description</span></h3>
                                </div>
                                {
                                    this.state.roleNameList.map((roleNameValue, roleNameIndex) => {
                                        return (
                                            <div style={{ marginBottom: 20 }} key={roleNameIndex}>
                                                <h4 className="text-white"><u>{roleNameValue.name}</u></h4>
                                                <div className="row">
                                                    <div className="col-md-1 text-white font-weight-bold"> {roleNameValue.id == 2 || roleNameValue.id == 6 ? 'Rationale' : 'Objective'}  </div>
                                                    <div className="col-md-11 text-white"> {roleNameValue.description}</div>
                                                </div>
                                                {
                                                    this.state.roleCardList.map((roleCardValue, roleCardIndex) => {
                                                        return (
                                                            <>
                                                                {roleCardValue.roleNameId && roleCardValue.roleNameId.id == roleNameValue.id && roleCardValue.moveNumber == 0 &&
                                                                    <div className="row" key={roleCardIndex}>
                                                                        <div className="col-md-1 text-white font-weight-bold">Move {roleCardValue.moveNumber + 1} </div>
                                                                        <div className="col-md-11 text-white"> {roleCardValue.description}</div>
                                                                    </div>
                                                                }
                                                                {roleCardValue.roleNameId && roleCardValue.roleNameId.id == roleNameValue.id && roleCardValue.moveNumber == 1 &&
                                                                    <div className="row" key={roleCardIndex}>
                                                                        <div className="col-md-1 text-white font-weight-bold">Move {roleCardValue.moveNumber + 1} </div>
                                                                        <div className="col-md-11 text-white"> {roleCardValue.description}</div>
                                                                        <br />
                                                                    </div>
                                                                }
                                                                {roleCardValue.roleNameId && roleCardValue.roleNameId.id == roleNameValue.id && roleCardValue.moveNumber == 2 &&
                                                                    <div className="row" key={roleCardIndex}>
                                                                        <div className="col-md-1 text-white font-weight-bold">Move {roleCardValue.moveNumber + 1} </div>
                                                                        <div className="col-md-11 text-white"> {roleCardValue.description}</div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }


                                {/* <h4 className="text-white"><u>Investor</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Objective </div>
                                    <div className="col-md-11 text-white"> Your objective is to maximise your return on investment ensuring that by the year end, you have at least the value you started with (1000 units).</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Decide how much to invest in the budget. Enter this amount and press play. Note that the maximum you can invest is limited by the amount in profit.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Decide the return on investment you would like to achieve from the profit generated. Enter this amount and press play.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Sales</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Objective </div>
                                    <div className="col-md-11 text-white"> Your objective is to generate demand by offering innovative products & services to your market.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Decide the products & services necessary to grow demand. Enter this amount and press play.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Decide the budget required for future innovation. Enter this amount and press play.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Markets</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Rationale </div>
                                    <div className="col-md-11 text-white"> Demand is influenced by the attractiveness of your products & services as well as by your reputation.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Calculate the amount to add to, or remove from demand so that it matches the amount in products & services. Enter this amount and press play.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Calculate the amount to add to, or remove from demand so that the difference between demand and reputation is halved. Enter this amount and press play.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Human Resources</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Objective </div>
                                    <div className="col-md-11 text-white"> Your objective is to use feedback from your performance to engage and retain the talent necessary to sustain your growth </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Compare performance with reputation. If the amount in performance is less than or equal to reputation, enter the amount and press play. If greater, enter the equivalent amount to that in reputation and press play. Engagement will increase accordingly with excess performance going to waste.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Decide the number of engaged employees you wish to retain. Enter this amount and press play.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 3 </div>
                                    <div className="col-md-11 text-white"> Your reputation is making you attractive to millennials. Decide where they would make the greatest difference to the organisation by clicking on profit, innovation, engagement or capability. An equivalent of 20% of the value in reputation will be allocated accordingly.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Head hunters</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Objective </div>
                                    <div className="col-md-11 text-white"> Your objective is to maximise your fee income through the turnover of talent ensuring that by the year end, you have at least the value you started with (1000 units).</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Decide how much talent to offer the business. Enter this amount and press play. Note that the maximum you can offer is limited by the amount in engagement.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Decide how many employees to head hunt away from the business as talent to offer other clients. Enter this amount and press play.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Operations </u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Objective </div>
                                    <div className="col-md-11 text-white"> Your objective is to grow reputation by ensuring you have the capability to deliver high quality products & services. </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Decide the performance necessary to grow your reputation. Enter this amount and press play.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Decide the capability required to continuously improve your performance in the future. Enter this amount and press play.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Customers</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Rationale </div>
                                    <div className="col-md-11 text-white"> Reputation is influenced by your performance as well as by demand (social proofing).</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Calculate the amount to add to, or remove from reputation so that it matches the amount in performance. Enter this amount and press play.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Calculate the amount to add to, or remove from reputation so that the difference between reputation and demand is halved. Enter this amount and press play.</div>
                                </div>
                                <br />
                                <h4 className="text-white"><u>Finance</u></h4>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Objective </div>
                                    <div className="col-md-11 text-white"> Your objective is to decide the mix of retained profit and investment necessary to sustain your growth.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 1 </div>
                                    <div className="col-md-11 text-white"> Compare products & services with demand. If the amount in products & services is less than or equal to demand, enter the amount and press play. If greater, enter the equivalent amount to that in demand and press play. Profit will increase accordingly with excess products & services going to waste.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 2 </div>
                                    <div className="col-md-11 text-white"> Decide the amount of profit you wish to retain. Enter this amount and press play.</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1 text-white font-weight-bold">Move 3 </div>
                                    <div className="col-md-11 text-white"> Demand for your products & services is enabling you to increase prices. Decide where the resulting funds would make the greatest difference by clicking on profit, innovation, engagement or capability. An equivalent of 20% of the value in demand will be allocated accordingly.</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer childProps={this.props} />
            </div>
        )
    }
}