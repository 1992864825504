import React, { Component } from 'react';
import { connect } from 'react-redux'

import { updateTabNavigation } from '../store/actions'

class Dashboard extends Component {

    onClickTab = (tabName) => {
        this.props.updateTabNavigation({ TabName: tabName })
    }

    // <section className="content">
    // <div className="container-fluid">
    //     <div className="row">


    render() {
        const { tabNames } = this.props
        return (
            <div className="card" style={{paddingTop: '20px'}}>
                <section className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3 style={{color: 'transparent'}}>-</h3>
                                    <h5>{tabNames[1]}</h5>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-bag"></i>
                                </div>
                                <a onClick={this.onClickTab.bind(this, tabNames[1])} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3 style={{color: 'transparent'}}>-</h3>
                                    <h5>{tabNames[2]}</h5>
                                </div>
                                <div className="icon">
                                    <i className="ion ion-person"></i>
                                </div>
                                <a onClick={this.onClickTab.bind(this, tabNames[2])} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3 style={{color: 'transparent'}}>-</h3>
                                    <h5>{tabNames[3]}</h5>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-gamepad"></i>
                                </div>
                                <a onClick={this.onClickTab.bind(this, tabNames[3])} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3 style={{color: 'transparent'}}>-</h3>
                                    <h5>{tabNames[4]}</h5>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-cog"></i>
                                </div>
                                <a onClick={this.onClickTab.bind(this, tabNames[4])} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3 style={{color: 'transparent'}}>-</h3>
                                    <h5>{tabNames[5]}</h5>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-cog"></i>
                                </div>
                                <a onClick={this.onClickTab.bind(this, tabNames[5])} className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    tabSettings: state.userReducer.tabSettings,
    tabNames: state.userReducer.tabNames,

})

const mapDispatchToProps = dispatch => ({
    updateTabNavigation: (data) => { dispatch(updateTabNavigation(data)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)
