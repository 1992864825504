import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import logoImg from '../assets/images/logo.png'



function GameLoading() {
    return (
        <div>
            <LoadingOverlay active={true} spinner>
                <div class="logo1">
                    {/* <img src={logoImg} alt="example" /> */}
                    <div class="loader"></div>
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default GameLoading;
