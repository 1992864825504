import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import request from 'superagent';
import { connect } from 'react-redux'

export default class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            gameSetupList: [],
            roleCardList: [],
            isAPICalled: false
        }
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    componentWillMount() {
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        if (teamLoginDetails != null) {
            this.loadstaticpages(teamLoginDetails.organisationId)
        } else {

        }
    }

    async loadstaticpages(orgID) {
        const r = await request.post(`${this.apiBase}/getStaticPage`)
            .send({
                organisationId: orgID,
                pageName: 'Game Setup'
            })
        const response = r.body;
        if (response.success) {
            this.setState({
                gameSetupList: response.ResponsePacket,
                isAPICalled: true
            })
        }
    }

    render() {
        return (
            <div className="mainOuterDiv" >
                <Header childProps={this.props} />
                <section className="match-section set-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="section-title">
                                <h3>Game<span> Setup</span></h3>
                            </div>
                            {this.state.isAPICalled && this.state.gameSetupList.length > 0 ?
                                <div dangerouslySetInnerHTML={{ __html: this.state.gameSetupList[0].description }}></div>
                                :
                                this.state.isAPICalled && this.state.gameSetupList.length == 0 ?
                                    <div>
                                        <p>The game will be introduced by a 90 minute webinar during which time you will be introduced to the collaboration principles underpinning the game, learn how to play and practise the first round. You will then play the game asynchronously over an agreed period, instructions for which will be explained during the webinar. The game will be concluded with a further 90m webinar to help you to extract and apply the lessons. Dial in details for the webinar together with user names and passwords for the game will be emailed to you separately. </p>
                                        <p>  Enter your login details including your username and password. Once logged in, you will see a copy of the Collaboration Game board together with instructions for the Investor role. The Investor will also see their role highlighted with a text box. Adjacent to the instructions, you will see game information and your profile. To the right of the game board, you will see a score card illustrating yours and the best results for each previous trading quarter. Hovering over the results will reveal the decisions of each last round as you play. Should you wish to learn more about each others roles at any time, click on the ROLE DESCRIPTIONS tab.  </p>
                                        <p> Before playing asynchronously, we recommend setting up a WhatsApp group between you and your follow team members to share your thinking behind each decision and to prompt the next player that it is their turn to move. . We also recommend that you set up a Zoom or conference call each few days to agree decision protocols, share ideas and reflect on your learning. </p>
                                        <p> Please note, you may need to enable Flash player on your browser to play the game. If you have any problems such as the game board not appearing, please try a different browser such as firefox, talk with your IT representative or email <a href="mailto:info@insideoutpartnership.co.uk" >info@insideoutpartnership.co.uk.</a> </p>
                                    </div>
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}