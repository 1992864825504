import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import request from 'superagent';
import { connect } from 'react-redux'

export default class GameRules extends Component {

    constructor(props) {
        super(props)
        this.state = {
            gameRuleList: [],
            isAPICalled: false
        }
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    componentWillMount() {
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        if (teamLoginDetails != null) {
            this.loadstaticpages(teamLoginDetails.organisationId)
            // this.loadRoleCards(teamLoginDetails.organisationId)
        } else {

        }
    }

    async loadstaticpages(orgID) {
        const r = await request.post(`${this.apiBase}/getStaticPage`)
            .send({
                organisationId: orgID,
                pageName: 'Game Rule'
            })
        const response = r.body;
        if (response.success) {
            this.setState({
                gameRuleList: response.ResponsePacket,
                isAPICalled: true
            })
        }
    }

    render() {
        return (
            <div className="mainOuterDiv" >
                <Header childProps={this.props} />
                <section className="match-section set-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="section-title">
                                <h3>Game <span> Rules</span></h3>
                            </div>
                            {this.state.isAPICalled && this.state.gameRuleList.length > 0 ?
                                <div dangerouslySetInnerHTML={{ __html: this.state.gameRuleList[0].description }}></div>
                                :
                                this.state.isAPICalled && this.state.gameRuleList.length == 0 ?
                                    <div>
                                        <p> The game represents the interdependencies of a typical business. It is played in a series of rounds, each representing a trading quarter. Typically the game is played over 8 to 12 trading quarters. Each game involves 8 players occupying eight roles. Each role involves either 2 or 3 decisions or calculations. You must decide which role you want to play. The game begins with the investor role. Both moves are made by the investor before the turn of sales. Again, both moves are played before the turn of markets …. and so the game continues in a clockwise sequence until Finance play their third move. At this point, the score board will update and the Investor can begin the next round. Note that some calculations will result in a fraction. When this happens, always round up to the next whole number. If you make an error, you will be notified and asked to recalculate.</p>
                                        <p> We suggest you have a conference call or Zoom conversation every third round in order to learn from the game based on the following questions: </p>
                                        <p>After round 3 - what are each of your assumptions about the game, each others objectives and roles, the interdependencies between you and the impact of your decisions on each other?</p>
                                        <p>After round 6 - what is your target score for the end of the game, what is your strategy for the remaining rounds and what do you need from each other in order to achieve it?</p>
                                        <p>After round 9 - how are you making decisions together, to what extent are you embracing different opinions, what is the quality of dialogue like and what could you improve?</p>
                                        <p>The game is complete once the twelfth round has been played. Lessons from the game will be discussed during the final webinar. Please bring your answers to these questions with you to the webinar.</p>
                                    </div>
                                    :
                                    <div></div>
                            }
                        </div>
                    </div>
                </section>
                <Footer childProps={this.props} />
            </div>
        )
    }
}