import React from 'react'
import { DragPreviewImage } from 'react-dnd'
import { DragSource } from 'react-dnd'
import CountUp from 'react-countup'

const _style = {
    border: '2px solid #e62b2b',
    cursor: 'move',
}

function getTextWidth(ctx, style, text) {
    if (typeof ctx.measureText === 'function') {
        return ctx.measureText(text).width
    } else {
        var perCharWidth = style.fontSize / 1.7
        return text.length * perCharWidth
    }
}

function parseBoxShadow(style) {
    var parts = (style.boxShadow || '').replace(/px/g, '').split(/[^,] /)
    var offsetX = parts[0]
    var offsetY = parts[1]
    var blur = parts[2]
    var color = parts[3]
    return {
        shadowBlur: parseInt(blur, 10) || 0,
        shadowColor: color || 'transparent',
        shadowOffsetX: parseInt(offsetX, 10) || 0,
        shadowOffsetY: parseInt(offsetY, 10) || 0
    }
}

var defaultStyle = {
    backgroundColor: '#efefef',
    borderColor: '#1a1a1a',
    color: '#1a1a1a',
    fontSize: 24,
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5
}


function _createDragPreview(text, style) {

    if (!style) style = defaultStyle

    var shadowStyle = parseBoxShadow(style)
    var marginBottom = shadowStyle.shadowOffsetY + (shadowStyle.shadowBlur * 2)
    var marginRight = shadowStyle.shadowOffsetX + (shadowStyle.shadowBlur * 2)
    var rectHeight = style.paddingTop + style.fontSize + style.paddingBottom
    var rectStrokeWidth = 1

    var c = document.createElement('canvas')
    c.height = rectHeight + marginBottom
    var ctx = c.getContext('2d')

    ctx.font = style.fontSize + 'px sans-serif' // once before for measurement
    var textWidth = getTextWidth(ctx, style, text)
    var rectWidth = style.paddingLeft + textWidth + style.paddingRight

    ctx.canvas.width = style.paddingLeft + textWidth + style.paddingRight + marginRight + (rectStrokeWidth * 2)
    ctx.font = style.fontSize + 'px sans-serif' // once after for actually styling

    ctx.rect(0, 0, rectWidth, rectHeight)

    ctx.save()
    ctx.fillStyle = style.backgroundColor
    ctx.strokeStyle = style.borderColor
    ctx.shadowColor = shadowStyle.shadowColor
    ctx.shadowBlur = shadowStyle.shadowBlur
    ctx.shadowOffsetX = shadowStyle.shadowOffsetX
    ctx.shadowOffsetY = shadowStyle.shadowOffsetY

    ctx.fill()
    ctx.stroke()
    ctx.restore()

    ctx.fillStyle = style.color
    ctx.fillText(text, style.paddingLeft, (style.paddingTop * .75) + style.fontSize);

    return c.toDataURL()
}

const ChipsDropable = ({ counterValue, isDragging, connectDragSource, connectDragPreview, dynamicClass, chipValue, chipIndex, onChipDropped, prefix, dynamicStyle, dynamicBGColor }) => {

    const opacity = isDragging ? 0.4 : 1
    const textImage = _createDragPreview(counterValue)

    return (
        <>
            <DragPreviewImage connect={connectDragPreview} src={textImage} />
            <div ref={connectDragSource} style={{ ..._style, opacity }} className={dynamicClass}>
                <CountUp
                    prefix={prefix}
                    redraw={false}
                    end={chipValue}
                    style={dynamicStyle}
                />
            </div>
        </>
    )
}

export default DragSource(
    'CHIP',
    {
        beginDrag: (props) => ({ chipIndex: props.chipIndex }),
        endDrag(props, monitor) {
            const item = monitor.getItem()
            const dropResult = monitor.getDropResult()
            if (dropResult) {
                // alert(`You dropped ${item.chipIndex} into ${dropResult.chipIndex}!`)
                props.onChipDropped(item.chipIndex, dropResult.chipIndex)
            }
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview()
    }),
)(ChipsDropable)
