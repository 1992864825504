import React from 'react';

export default class MessageSendForm extends React.Component {
    constructor() {
        super()
        this.state = {
            message: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        this.setState({
            message: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.sendMessage(this.state.message)
        this.setState({
            message: ''
        })
    }

    render() {
        const { currentUserPlayerID } = this.props
        return (
            <form className="msger-inputarea" onSubmit={this.handleSubmit}>
                <input
                    onChange={this.handleChange}
                    value={this.state.message} type="text" className="msger-input" placeholder="Enter your message..." />
                <button type="submit" disabled={currentUserPlayerID == null} className="msger-send-btn">Send</button>
            </form>
        )
    }
}