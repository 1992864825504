import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer-section set-bg" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="fs-logo">
                                {/* <div className="logo">
                                    <a href="./index.html"><img src={logoImg} alt="" /></a>
                                </div> */}
                                {/* <p className="mb-2"> The Collaboration Game is a Registered Design </p> */}
                                <p> <small>Copyright © 2020 The Inside Out Partnership Limited</small> </p>
                                {/*  <ul>
                                        <li><i className="fa fa-envelope"></i> Info.colorlib@gmail.com</li>
                                        <li><i className="fa fa-copy"></i> +(12) 345 6789 10</li>
                                        <li><i className="fa fa-thumb-tack"></i> 40 Baria Sreet 133/2 New York City, United State</li>
                                    </ul>
                                    <div className="fs-social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-tumblr"></i></a>
                                        <a href="#"><i className="fa fa-linkedin"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}