import React from 'react';
import request from 'superagent';
// import { API_URL, API_PORT, CHAT_SOCKET_PORT, IS_PRODUCTION } from '../constants'
import _ from 'lodash';
import './board.css';
import { Card, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import backgroundImgMobileDefault from '../assets/images/game-mobile.PNG'
import backgroundImgMobile from '../assets/images/79999 Inside out SwissRE Board master 1300x650.jpg'

import Header from '../websitePanel/Header'
import Footer from '../websitePanel/Footer'
// import Chat from './chat'
import CountUp from 'react-countup'
import { connect } from 'react-redux'
import {
    playerLogin,
    storeCurrentUser,

    updateCurrentPlayerID,
    removeCurrentPlayerID,

    storeCurrentGameDetails,
    storeCurrentPlayerCredentials,
    setGamePlayerToStore,
    setNotificationToStore

} from '../store/actions'
import * as game from './game'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import $ from 'jquery';
import Chips from './Chips';
import ChipsDropable from './ChipsDropable';
import MessageList from './MessageList';
import MessageSendForm from './MessageSendFrom';
import LoadingOverlay from 'react-loading-overlay';
import socketIOClient from "socket.io-client";
import { requestFirebaseNotificationPermission } from '../firebaseInitClient'

const monthNames = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
var socket;
let counterInterval
let counterTimeout
let isDefaultRoleName = true
class Board extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            boardOuterHeight: 0,
            boardOuterWidth: 0,
            counterValue: 0,

            gameID: "",
            creating: false,
            created: false,
            model: null,
            perticullarGameData: {},
            scoreDataMyTeam: [],
            chatMessages: [],
            chatMessage: 'hello hello',
            teamDetails: {},

            organisation: "",
            teamName: "",
            isLoading: false,
            globalGameId: null,
            globalOrganisationId: null,
            gameBoardImage: null,
            ioTeamArray: [],
            roleCardList: [],
            isBoardLoaded: false,
            manualDropHelighted: false,
            scoreDataAllTeams: []
        }

        if (process.env.REACT_APP_STAGE == 'production') {
            socket = socketIOClient('wss://www.collaboration-game.tk', {
                path: '/chat',
                transports: ['websocket']
            });
        } else {
            socket = socketIOClient(`${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_CHAT_SOCKET_PORT}`)
        }

        this.joinGameByUser = this.joinGameByUser.bind(this);
        this.sendMessage = this.sendMessage.bind(this)

        this.handleButtonPress = this.handleButtonPress.bind(this)
        this.handleButtonRelease = this.handleButtonRelease.bind(this)

        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`

    }

    handleButtonPress(isPlus, type, evt) {
        if (type == 'short') {
            if (isPlus) {
                this.setState({
                    counterValue: parseInt(this.state.counterValue) + 1
                })
            } else if (!isPlus && parseInt(this.state.counterValue) > 0) {
                this.setState({
                    counterValue: parseInt(this.state.counterValue) - 1
                })
            }
        } else if (type == 'long') {
            counterTimeout = setTimeout(() => {
                counterInterval = setInterval(() => {
                    if (!isPlus && parseInt(this.state.counterValue) > 0) {
                        this.setState({
                            counterValue: parseInt(this.state.counterValue) - 1
                        })
                    } else if (isPlus) {
                        this.setState({
                            counterValue: parseInt(this.state.counterValue) + 1
                        })
                    } else {
                        clearTimeout(counterTimeout);
                        clearInterval(counterInterval)
                        return
                    }
                }, 50)
            }, 1000)
        }

    }

    handleButtonRelease() {
        clearTimeout(counterTimeout);
        clearInterval(counterInterval)
    }

    componentDidMount() {
        if (this.boardOuter) {
            const height = this.boardOuter.clientHeight;
            const width = this.boardOuter.clientWidth;
            this.setState({ boardOuterHeight: height, boardOuterWidth: width });
        }

        requestFirebaseNotificationPermission()
            .then((firebaseToken) => {
                this.setState({
                    firebaseToken: firebaseToken
                })
                // eslint-disable-next-line no-console
                console.log(firebaseToken);
            })
            .catch((err) => {
                return err;
            });


        $(".canvas-open1").on('click', function () {
            $(".offcanvas-menu-wrapper1").addClass("show-offcanvas-menu-wrapper1");
            $(".offcanvas-menu-overlay1").addClass("active");
            $(this).toggleClass("active");

        });

        $(".canvas-close1, .offcanvas-menu-overlay1").on('click', function () {
            $(".offcanvas-menu-wrapper1").removeClass("show-offcanvas-menu-wrapper1");
            $(".offcanvas-menu-overlay1").removeClass("active");
            $(".canvas-open1").removeClass("active");
        });

        $(".infoIconOuter").on('click', function () {
            $(".navtrnOuter").toggleClass("d-none");
        });

        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll <= 2) {
                $(".header-section").removeClass("d-noneMobile");
            }
            else {
                $(".header-section").addClass("d-noneMobile");
            }
        })

        socket.on("chatFromServer", (gameid) => {
            if (this.state.globalGameId == gameid) {
                this.loadChatData()
            }
        })

        socket.on("gameJoinFromServer", (gameid) => {
            if (this.state.globalGameId == gameid) {
                this.loadIOGameWithData(gameid)
            }
        })

        socket.on("scoreUpdateFromServer", (gameid) => {
            if (this.state.globalGameId == gameid) {
                this.loadScoreData()
            }
        })

        socket.on("playerRemovedFromServer", (dtObj) => {
            const { currentUserPlayerID, currentUserCredentials } = this.props
            if (this.state.globalGameId == dtObj.gameId && dtObj.playerId + '' == currentUserPlayerID + '') {
                localStorage.setItem('currentPlayerStorage', JSON.stringify({
                    currentUserPlayerID: null,
                    currentUserCredentials: null
                }))

                this.props.storeCurrentPlayerCredentials({
                    currentUserPlayerID: null,
                    currentUserCredentials: null,
                })
            }
        })

        socket.on("playerJoinedFromServer", (gameid) => {
            console.log('playerJoinedFromServer', this.state.globalGameId + " == " + gameid)
            if (this.state.globalGameId == gameid) {
                this.loadNotificationData()
            }
        })
    }

    componentWillMount() {
        const globalGameId = JSON.parse(localStorage.getItem('globalGameId'));
        const currentPlayerStorage = JSON.parse(localStorage.getItem('currentPlayerStorage'))
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        if (globalGameId && teamLoginDetails != null) {
            this.setState({ globalGameId: globalGameId }, () => {
                this.loadGameWithData(globalGameId)
                this.loadRoleNames(teamLoginDetails.organisationId)
                this.loadChatData()
                this.loadScoreData()
                this.loadNotificationData()
                if (currentPlayerStorage && currentPlayerStorage != null) {
                    this.props.storeCurrentPlayerCredentials({
                        currentUserPlayerID: currentPlayerStorage.currentUserPlayerID,
                        currentUserCredentials: currentPlayerStorage.currentUserCredentials,
                    })
                } else {
                    this.props.storeCurrentPlayerCredentials({
                        currentUserPlayerID: null,
                        currentUserCredentials: null,
                    })
                }
            })
        } else {
            this.props.childProps.history.push("/")
        }
    }

    async sendMessage(text) {
        await this.createChat(text)
        socket.emit("chatFromClient", this.state.globalGameId);
    }

    async loadChatData() {
        const r = await request
            .post(`${this.apiBase}/getChat`)
            .send({
                gameId: this.state.globalGameId,
            })
        const response = r.body
        if (response.success) {
            this.setState({
                chatMessages: response.ResponsePacket
            })
        }
    }

    async loadNotificationData() {
        const r = await request
            .post(`${this.apiBase}/getNotifications`)
            .send({
                gameId: this.state.globalGameId,
            })
        const response = r.body
        if (response.success) {
            // this.setState({
            //     chatMessages: response.ResponsePacket
            // })
            this.props.setNotificationToStore(response.ResponsePacket)
        }
    }

    async createChat(text) {
        const r = await request
            .post(`${this.apiBase}/createChat`)
            .send({
                gameId: this.state.globalGameId,
                playerId: this.props.currentUserPlayerID,
                message: text,
                isDeleted: true,
            });
        const response = r.body
        if (response.success) {
            this.setState({
                value: "0",
                playerId: "",
                gameId: "",
                teamId: "",
            })
        }
    }

    async loadGameWithData(_id) {
        this.setState({ isLoading: false })
        const r = await request.post(`${this.apiBase}/getGame`)
            .send({
                _id: _id
            })
        const response = r.body;
        if (response.success) {
            const mongoResult = response.ResponsePacket.mongoResult
            const ioResult = response.ResponsePacket.ioResult
            this.setState({
                isBoardLoaded: true,
                gameId: mongoResult.gameId,
                organisation: mongoResult.organisationId.organisation,
                globalOrganisationId: mongoResult.organisationId._id,
                gameBoardImage: mongoResult.gameBoardImage,
                teamName: mongoResult.teamName,
                isLoading: false,

                ioTeamArray: ioResult.players
            }, () => {
                this.props.storeCurrentGameDetails({
                    currentGameID: mongoResult.gameId
                })
                this.loadRoleCards()
            })
        } else {
            this.setState({ isLoading: false })
            localStorage.setItem('currentPlayerStorage', JSON.stringify({ currentUserPlayerID: null, currentUserCredentials: null }))
            localStorage.setItem('globalGameId', null)
            localStorage.setItem('teamLoginDetails', null)
            this.props.storeCurrentPlayerCredentials({ currentUserPlayerID: null, currentUserCredentials: null })
            this.props.childProps.history.push("/")
        }
    }

    async loadIOGameWithData(_id) {
        const r = await request.post(`${this.apiBase}/getIOGame`)
            .send({
                _id: _id
            })

        const response = r.body;
        if (response.success) {
            const ioResult = response.ResponsePacket.ioResult
            this.setState({
                ioTeamArray: ioResult.players
            })
        }
    }

    async loadRoleNames(orgID) {
        const r = await request.post(`${this.apiBase}/getRoleName`)
            .send({
                organisationId: orgID
            })
        const response = r.body;
        if (response.success) {
            if (response.ResponsePacket && response.ResponsePacket.length == 8) {
                isDefaultRoleName = false
                this.props.setGamePlayerToStore(response.ResponsePacket)
            } else {
                isDefaultRoleName = true
                this.props.setGamePlayerToStore(this.props.defaultRoleNames)
            }
        }
    }

    async loadRoleCards() {
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'));
        const r = await request.post(`${this.apiBase}/getRoleCard`)
            .send({
                organisationId: teamLoginDetails.organisationId
            })
        const response = r.body
        if (response.success) {
            if (response.ResponsePacket.length == 18) {
                this.setState({
                    roleCardList: response.ResponsePacket,
                    isLoading: false
                })
            } else {
                this.setState({
                    roleCardList: this.props.defaultRoleCards,
                    isLoading: false
                })
            }
        } else {
            this.setState({
                isLoading: false
            })
        }
    }


    async joinGameByUser(playerid, playername) {
        confirmAlert({
            title: 'Join Game',
            message: "Do you want to join as " + playername + "?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        this.switchPlayer(playerid, playername)
                        // this.joinGame(playerid, playername)
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {

                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async switchPlayer(playerid, playername) {
        const { ioTeamArray } = this.state
        const { currentUserPlayerID, currentUserCredentials } = this.props
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'));
        this.setState({ isLoading: true })

        const r = await request
            .post(`${this.apiBase}/switchPlayer`)
            .send({
                //join parameters
                gameId: this.state.globalGameId,
                ioGameId: teamLoginDetails.gameId,
                joinPlayerId: playerid + '',
                joinPlayerName: playername,
                organisationId: this.state.globalOrganisationId,
                playerTokens: this.state.firebaseToken,

                //leave param
                leavePlayerId: currentUserPlayerID,
                leavePlayerCredentials: currentUserCredentials,

                //extra params
                isForceJoin: ioTeamArray[playerid] && ioTeamArray[playerid].name ? true : false,
                isAlreadyJoined: currentUserPlayerID && currentUserCredentials ? true : false
            })

        const response = r.body

        if (response.success) {
            this.setState({ isLoading: false }, () => {
                localStorage.setItem('currentPlayerStorage', JSON.stringify({
                    currentUserPlayerID: playerid,
                    currentUserCredentials: response.ResponsePacket.credentials
                }))
                this.props.storeCurrentPlayerCredentials({
                    currentUserPlayerID: playerid,
                    currentUserCredentials: response.ResponsePacket.credentials
                })
                socket.emit("gameJoinFromClient", this.state.globalGameId);
            })

        } else {
            this.setState({ isLoading: false }, () => {
                this.showAlert("Join Game", "Please try clicking on any other role.")
            })
        }
    }

    async joinGame(playerid, playername) {
        const { currentGameName, currentGameID, } = this.props
        const { ioTeamArray } = this.state
        if (ioTeamArray.length > 0) {
            let count = 0
            for (let i = 0; i < ioTeamArray.length; i++) {
                if (ioTeamArray[i].name) {
                    count += 1
                }
            }

            if (count <= 1) {
                this.showAlert("Leave Game", "Unable to leave the Game!")
                return
            }
        }

        if (this.props.currentUserPlayerID == playerid) {
            return
        }

        // if (this.props.ctx.currentPlayer + '' != playerid + '') {
        //     this.showAlert("You can join " + this.props.gamePlayers[this.props.ctx.currentPlayer].name + " role for now.")
        //     return
        // }

        // if ((this.props.currentUserPlayerID != null || this.props.currentUserPlayerID != undefined) && this.props.currentUserPlayerID != playerid) {
        //     this.showAlert("You already joined as " + this.props.gamePlayers[this.props.currentUserPlayerID].name + " role, first you need to leave that role.")
        //     return
        // }

        if (currentGameName && currentGameID) {
            const r = await request
                .post(`${this.apiBase}/join`)
                .send({
                    gameName: currentGameName,
                    gameId: currentGameID,
                    playerID: playerid + '',
                    playerName: playername,
                })
            const response = r.body
            if (response.ok) {
                localStorage.setItem('currentPlayerStorage', JSON.stringify({
                    currentUserPlayerID: playerid,
                    currentUserCredentials: response.credentials
                }))
                this.props.storeCurrentPlayerCredentials({
                    currentUserPlayerID: playerid,
                    currentUserCredentials: response.credentials
                })
                socket.emit("gameJoinFromClient", this.state.globalGameId);
            } else {
                this.showAlert("Join Game", "Already joined by another player!")
            }
        }
    }

    leaveGameByUser(playerid) {
        confirmAlert({
            title: 'Game Leave',
            message: "Do you want to quit from game?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        this.leaveGame(playerid)
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {

                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async leaveGame(playerid) {
        this.setState({ isLoading: false })
        const { currentGameName, currentGameID, currentUserCredentials } = this.props
        if (currentGameName && currentGameID) {
            const r = await request
                .post(`${this.apiBase}/leave`)
                .send({
                    gameName: currentGameName,
                    gameId: currentGameID,
                    playerID: playerid + '',
                    credentials: currentUserCredentials,
                })

            const response = r.body
            if (response.ok) {
                localStorage.setItem('currentPlayerStorage', JSON.stringify({
                    currentUserPlayerID: null,
                    currentUserCredentials: null
                }))
                this.props.storeCurrentPlayerCredentials({
                    currentUserPlayerID: null,
                    currentUserCredentials: null
                })
            }
            // else {
            //     this.showAlert("You need to join the game first.")
            // }
        }
    }

    showAlert = (title, msg) => {
        confirmAlert({
            title: title,
            message: msg,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        // this.props.undo()
                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })

    }

    async loadScoreData() {
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'));
        const r = await request
            .post(`${this.apiBase}/getScore`)
            .send({
                gameId: this.state.globalGameId,
                organisationId: teamLoginDetails.organisationId
            })

        const response = r.body
        if (response.success) {
            this.setState({
                scoreDataMyTeam: response.ResponsePacket.myTeam,
                scoreDataAllTeams: response.ResponsePacket.allTeams
            }, () => {
                // let originalArr = this.state.scoreDataAllTeams
                // let filteredArr = []
                // if (originalArr.length > 0) {
                //     for (let i = 0; i < originalArr.length; i++) {
                //         if(originalArr[i].gameId){

                //         }
                //     }
                // }
            })
        }
    }

    async onCreateGame() {
        this.setState({
            ...this.state,
            creating: true,
        });

        const r = await request
            .post(`${this.apiBase}/create`)
            .send({
                players: this.props.gamePlayers.length,
                model: this.state.model,
                names: this.props.playerNames,
            });

        const gameId = r.body.game;

        for (var i = 0; i < r.body.credentials.length; i++) {
            this.setState({
                ...this.state,
                secret: {
                    ...this.props.gameSecret,
                    [i]: r.body.credentials[i],
                },
            });
        }

        this.setState({
            ...this.state,
            gameID: gameId,
            created: true,
        }, () => {
            // console.log(this.state)
        });
    }

    onConterClick = (type) => {
        if (type == 'plus') {
            this.setState({
                counterValue: parseInt(this.state.counterValue) + 1
            })
        } else if (type == 'minus' && parseInt(this.state.counterValue) > 0) {
            this.setState({
                counterValue: parseInt(this.state.counterValue) - 1
            })
        }
    }

    onClickSidebar = () => {
        $(".canvas-open1").on('click', function () {
            $(".offcanvas-menu-wrapper1").addClass("show-offcanvas-menu-wrapper1");
            $(".offcanvas-menu-overlay1").addClass("active");
        });

        $(".canvas-close1, .offcanvas-menu-overlay1, .card-header-pills .nav-item").on('click', function () {
            $(".offcanvas-menu-wrapper1").removeClass("show-offcanvas-menu-wrapper1");
            $(".offcanvas-menu-overlay1").removeClass("active");
        });
    }

    setDynamicClass = (index) => {
        let classname = [' circle' + index]

        if (index == 0 || index == 11) {
            classname.push('  circle-big ')
        } else if (index == 12) {
            classname.push('  circle-waste ')
        } else if (index == 13) {
            classname.push('  circle-growth ')
        } else {
            classname.push('  circle-small ')
        }

        // <div key={index} className={'circle-waste' + ' circle' + index}>
        //     <CountUp prefix={'Waste </br>'}
        //         redraw={false}
        //         end={value}
        //         style={{ fontSize: '13px', color: 'black', fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}
        //     />
        // </div>


        // <div key={index} className={'circle-growth' + ' circle' + index}>
        //     <CountUp prefix={'Growth </br>'}
        //         redraw={false}
        //         end={value}
        //         style={{ fontSize: '13px', fontFamily: "Verdana, Geneva, Tahoma, sans-serif", color: '#fff' }}
        //     />
        // </div>

        return [classname.toString().split(",").join(" ")]

    }

    setPrefix = (index) => {
        let prefix = ""

        if (index == 12) {
            prefix = 'Waste </br>'
        } else if (index == 13) {
            prefix = 'Growth </br>'
        } else {
            prefix = ""
        }

        return prefix
    }

    setStyle = (index) => {
        if (index == 12 || index == 13) {
            return { fontSize: '13px', color: 'black', fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }
        } else {
            return { fontSize: '16px', color: 'black', fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }
        }
    }

    setBackgroundColor = (index) => {
        if (index == 12) {
            return ""
        } else if (index == 13) {
            return ""
        } else {
            return "#fff"
        }
    }

    chipsRender = (index, value, isDropableChip) => {
        if (isDropableChip) {
            return (
                <ChipsDropable
                    prefix={this.setPrefix(index)}
                    key={index}
                    chipIndex={index}
                    onChipDropped={(chipFromIndex, chipToIndex) => this.onChipDropped(chipFromIndex, chipToIndex)}
                    isDragged={false}
                    // dynamicClass={(index == 0 || index == 11) ? 'circle-big' + ' circle' + index : 'circle-small' + ' circle' + index}
                    dynamicClass={this.setDynamicClass(index)}
                    dynamicStyle={this.setStyle(index)}
                    dynamicBGColor={this.setBackgroundColor(index)}
                    chipValue={value}
                    counterValue={this.state.counterValue}
                />
            )
        } else {
            return (
                <Chips
                    key={index}
                    prefix={this.setPrefix(index)}
                    chipIndex={index}
                    // dynamicClass={(index == 0 || index == 11) ? 'circle-big' + ' circle' + index : 'circle-small' + ' circle' + index}
                    dynamicClass={this.setDynamicClass(index)}
                    dynamicStyle={this.setStyle(index)}
                    dynamicBGColor={this.setBackgroundColor(index)}
                    chipValue={value}
                    openDialog={this.onChipClick.bind(index)}
                    manualDropHelighted={this.state.manualDropHelighted}
                />
            )
        }
    }

    onChipClick = (chipToIndex) => {
        const { ctx } = this.props
        if (((parseInt(ctx.currentPlayer) == 3 && ctx.numMoves == 2) || (parseInt(ctx.currentPlayer) == 7 && ctx.numMoves == 2)) && chipToIndex != null) {
            this.setState({
                manualDropHelighted: false
            }, () => this.onPlayMove(null, chipToIndex))
        }
    }

    onChipDropped = (chipFromIndex, chipToIndex) => {
        this.onPlayMove(chipFromIndex, chipToIndex)
    }

    async onPlayMove(chipFromIndex, chipToIndex) {
        const { ctx, G, moves } = this.props
        let movesOfPlayers = [1, 1, 1, 2, 1, 1, 1, 2]


        if (parseInt(ctx.currentPlayer) != parseInt(this.props.currentUserPlayerID)) {
            this.showAlert("Warning", "Ooops, its not your turn!")
            return
        }

        if (((parseInt(ctx.currentPlayer) == 3 && ctx.numMoves == 2) || (parseInt(ctx.currentPlayer) == 7 && ctx.numMoves == 2)) && chipToIndex == null) {
            // this.showAlert("Warning", "Please select the chip you wants to drop the amount on it!")
            this.setState({
                manualDropHelighted: true
            }
                // , () => this.showAlert("Alert", "Ooops, please refer to your role card and try again.")
            )

            return
        }

        // if (parseInt(this.state.counterValue) < 1) {
        //     this.showAlert("Amount can't be 0!")
        //     return
        // }

        let currentMove = game.move(G, ctx, parseInt(this.state.counterValue), chipFromIndex, chipToIndex)
        if (currentMove.isMoved == false) {
            G.cells = currentMove.result
            confirmAlert({
                title: 'Alert',
                message: currentMove.message,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                        }
                    }
                ],
                closeOnCllickOutside: false,
                closeOnEscape: false
            })
        } else {
            const turnArr = [8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 136, 144, 152, 160, 168, 176, 184, 192, 200, 208, 216, 224, 232, 240, 248, 256, 264, 272, 280, 288, 296, 304, 312, 320, 328, 336, 344, 352, 360, 368, 376, 384, 392]
            if (turnArr.includes(ctx.turn) && ctx.numMoves == 2) {
                G.cells = currentMove.result
                let totalOfChips = 0

                for (let T = 0; T < G.cells.length - 2; T++) {
                    totalOfChips = totalOfChips + G.cells[T]
                }

                const r = await request
                    .post(`${this.apiBase}/createScore`)
                    .send({
                        gameId: this.state.globalGameId,
                        organisationId: this.state.globalOrganisationId,
                        roundNumber: ctx.turn / 8,
                        principalValue: 3000,
                        totalOfChips: totalOfChips,
                        growthValue: G.cells[13],
                        wasteValue: G.cells[12],
                        isDeleted: false
                    })

                const response = r.body
                if (response.success) {
                    moves.clickCell(G.cells);
                    if (movesOfPlayers[ctx.currentPlayer] == 1 && ctx.numMoves == 1) {
                        setTimeout(() => {
                            moves.clickCell(G.cells)
                        }, 1500);
                    }

                    socket.emit("scoreUpdateFromClient", this.state.globalGameId);
                    console.log("score saved succesfully")
                } else {
                    this.showAlert("Error", "Something went wrong... while saving scores!")
                }
            } else {
                G.cells = currentMove.result
                moves.clickCell(G.cells);
                if (movesOfPlayers[ctx.currentPlayer] == 1 && ctx.numMoves == 1) {
                    setTimeout(() => {
                        socket.emit("lastMoveOfEachPlayerFromClient", this.state.globalGameId, ctx.currentPlayer, this.props.gamePlayers[parseInt(ctx.currentPlayer) + 1].name);
                        moves.clickCell(G.cells)
                    }, 1500);
                }
            }
        }
    }

    chipsRenderAccordingMove = (chipIndex, value) => {
        const { ctx, G } = this.props
        let arrChips = G.cells
        let chipsValues = {
            Investment: arrChips[0],
            Budget: arrChips[1],
            Innovation: arrChips[2],
            ProductionAndService: arrChips[3],
            Profit: arrChips[4],
            Demand: arrChips[5],
            Reputation: arrChips[6],
            QualityOfDelivery: arrChips[7],
            EmploymentEngagement: arrChips[8],
            Retention: arrChips[9],
            Capability: arrChips[10],
            Talent: arrChips[11],
            Waste: arrChips[12],
            Improvement: arrChips[13],
        }

        switch (parseInt(ctx.currentPlayer)) {
            case 0:
                if (ctx.numMoves == 0) {
                    if (chipIndex == 0) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 1) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div key={chipIndex} className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                } else if (ctx.numMoves == 1) {
                    if (chipIndex == 4) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 0) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                }

            case 1:
                if (ctx.numMoves == 0) {
                    if (chipIndex == 2) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 3) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                } else if (ctx.numMoves == 1) {
                    if (chipIndex == 1) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 2) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                }

            case 2:
                if (ctx.numMoves == 0) {
                    if (chipsValues.Demand > chipsValues.ProductionAndService) {
                        if (chipIndex == 12) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else if (chipIndex == 5) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else {
                        if (chipIndex == 13) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 5) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    }
                } else if (ctx.numMoves == 1) {
                    if (chipsValues.Demand < chipsValues.Reputation) {
                        if (chipIndex == 5) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else if (chipIndex == 13) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else {
                        if (chipIndex == 5) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 12) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    }
                }

            case 3:
                if (ctx.numMoves == 0) {
                    if (chipsValues.QualityOfDelivery <= chipsValues.Reputation) {
                        if (chipIndex == 7) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 8) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else if (chipsValues.QualityOfDelivery > chipsValues.Reputation) {
                        if (chipIndex == 7) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 8) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }

                } else if (ctx.numMoves == 1) {
                    if (chipIndex == 8) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 9) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                } else if (ctx.numMoves == 2) {
                    if (chipIndex == 13) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 2) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]
                    } else if (chipIndex == 4) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]

                    } else if (chipIndex == 8) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]
                    } else if (chipIndex == 10) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]
                    }
                }

            case 4:
                if (ctx.numMoves == 0) {
                    if (chipIndex == 11) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 9) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                } else if (ctx.numMoves == 1) {
                    if (chipIndex == 8) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 11) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                }

            case 5:
                if (ctx.numMoves == 0) {
                    if (chipIndex == 10) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 7) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                } else if (ctx.numMoves == 1) {
                    if (chipIndex == 9) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 10) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                }
            case 6:
                if (ctx.numMoves == 0) {
                    if (chipsValues.Reputation > chipsValues.QualityOfDelivery) {
                        if (chipIndex == 6) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 12) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else if (chipsValues.QualityOfDelivery > chipsValues.Reputation) {
                        if (chipIndex == 13) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 6) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    }

                } else if (ctx.numMoves == 1) {
                    if (chipsValues.Reputation < chipsValues.Demand) {
                        if (chipIndex == 6) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else if (chipIndex == 13) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else {
                        if (chipIndex == 6) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 12) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    }
                }

            case 7:
                if (ctx.numMoves == 0) {
                    if (chipsValues.ProductionAndService <= chipsValues.Demand) {
                        if (chipIndex == 3) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 4) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else if (chipsValues.ProductionAndService > chipsValues.Demand) {
                        if (chipIndex == 3) {
                            return [this.chipsRender(chipIndex, value, true)]
                        } else if (chipIndex == 4) {
                            return [this.chipsRender(chipIndex, value, false)]
                        } else {
                            return [<div className={this.setDynamicClass(chipIndex)}>
                                <CountUp redraw={false}
                                    prefix={this.setPrefix(chipIndex)}
                                    end={value}
                                    style={this.setStyle(chipIndex)}
                                />
                            </div>]
                        }
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }

                } else if (ctx.numMoves == 1) {
                    if (chipIndex == 4) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 1) {
                        return [this.chipsRender(chipIndex, value, false)]
                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                } else if (ctx.numMoves == 2) {
                    if (chipIndex == 13) {
                        return [this.chipsRender(chipIndex, value, true)]
                    } else if (chipIndex == 2) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]
                    } else if (chipIndex == 4) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]
                    } else if (chipIndex == 8) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]
                    } else if (chipIndex == 10) {
                        return [
                            this.chipsRender(chipIndex, value, false)
                        ]

                    } else {
                        return [<div className={this.setDynamicClass(chipIndex)}>
                            <CountUp redraw={false}
                                prefix={this.setPrefix(chipIndex)}
                                end={value}
                                style={this.setStyle(chipIndex)}
                            />
                        </div>]

                    }
                }
        }
    }


    getTabColorstatus = (index) => {

        const { ioTeamArray } = this.state
        const { currentUserPlayerID, currentUserCredentials, gamePlayers, ctx } = this.props
        let classname = []

        if (ioTeamArray[index] && ioTeamArray[index].name) {
            classname = [' isJoin ']
        }

        if (!ioTeamArray[index] || !ioTeamArray[index].name) {
            classname = [' isOffline ']
        }

        // if (currentUserPlayerID + '' == index + '' && currentUserCredentials) {
        //     classname.push('  isMeJoined ')
        // }

        if (ctx.currentPlayer == gamePlayers[index].id) {
            classname.push('  active active-tab ')
        }

        // if (index == parseInt(ctx.currentPlayer) + 1) {
        //     classname.push(' isNextPlayerAlerted ')
        // }

        return [classname.toString().split(",").join(" ")]
    }

    render() {
        const { ctx, G, gameMoves, currentUserPlayerID, currentUserCredentials, gamePlayers } = this.props
        const { perticullarGameData, isLoading } = this.state
        return (
            <LoadingOverlay active={isLoading} spinner >
                {
                    this.state.isBoardLoaded ?
                        <div className="gamePageOuter">
                            <Header childProps={this.props} />
                            <div className="offcanvas-menu-overlay1"></div>
                            <div className="offcanvas-menu-wrapper1 boardSliderRight">
                                <div className="canvas-close1">
                                    <i className="fa fa-angle-right"></i>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ipadHide">
                                        <Card className="cardBottomMenu mb-0">
                                            <Card.Footer>
                                                <Nav className="nav-fill" variant="pills">
                                                    {ctx.playOrder.map((value, indx) => {
                                                        return (
                                                            <Nav.Item key={indx}>
                                                                <Nav.Link
                                                                    onClick={e => (currentUserPlayerID == indx && this.props.currentUserCredentials) ? null : this.joinGameByUser(indx, gamePlayers[indx].name)}
                                                                    style={{ 'color': 'white !important', fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}
                                                                    className={this.getTabColorstatus(indx)}
                                                                >
                                                                    {gamePlayers[indx].name}
                                                                    {/* {(currentUserPlayerID + '' == indx + '' && this.props.currentUserCredentials) && <i className="fa fa-close"></i>} */}
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        )
                                                    })}
                                                </Nav>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="text-right col-md-12" ><a className='infoIconOuter'>  <i className="fa fa-info-circle"></i></a></div>
                                    <div className="col-md-12 navtrnOuter ipadHide d-none">
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-6 col-6"><button className="playerTurnBtn times-font"> </button> Player Turn
                                    <button className="offlineBtn times-font ml-4"></button> Offline
                                    {/* <div className="col-lg-5 col-sm-12 times-font">Click on role to see profile each other</div> */}
                                                <button className="onlineBtn times-font ml-4"> </button> Online</div>
                                            {/* <div className="col-lg-4 col-sm-6  col-6 times-font"><button className="nextPlayerAlertedBtn times-font"></button> Next Player Alerted</div> */}
                                            {/* <div className="col-lg-5 col-sm-12 times-font">Click on role to see profile each other</div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-sm-12 mt-5 mobileMrginTOP col-12">
                                        <Card className="rightCardOuter mt-0" style={{ height: this.state.boardOuterHeight }}>
                                            <Card.Body className="p-2">
                                                <Nav variant="pills">
                                                    <div className="MoveTabOuter">
                                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" id="roleCard1-tab" data-toggle="tab" href="#roleCard1" role="tab" aria-controls="roleCard1" aria-selected="true">Role Card</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="chat1-tab" data-toggle="tab" href="#chat1" role="tab" aria-controls="chat1" aria-selected="false">Chat</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="score1-tab" data-toggle="tab" href="#score1" role="tab" aria-controls="score1" aria-selected="false">Score</a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content" id="myTabContent">
                                                            {this.state.roleCardList.length > 0 &&
                                                                <div className="tab-pane fade show active" id="roleCard1" role="roleCard1Panel" aria-labelledby="roleCard1-tab">
                                                                    <Card.Title className='times-font' style={{ textTransform: 'uppercase', paddingBottom: 15 }}>{gamePlayers[parseInt(ctx.currentPlayer)].name}</Card.Title>
                                                                    {
                                                                        !isDefaultRoleName ?
                                                                            <span> <p className="times-font"> {gamePlayers[ctx.currentPlayer].description}</p></span>
                                                                            :
                                                                            <span> <p className="times-font"> {gameMoves[ctx.currentPlayer]}</p></span>
                                                                    }
                                                                    {
                                                                        this.state.roleCardList.map((roleVal, roleIndex) => {
                                                                            if (roleVal.roleNameId && roleVal.roleNameId.id + "" == ctx.currentPlayer) {
                                                                                return (
                                                                                    <span key={roleIndex}>
                                                                                        <p className="times-font">
                                                                                            <span className="font-weight-bold">Move {roleVal.moveNumber + 1 + ' '}
                                                                                                {ctx.numMoves == roleVal.moveNumber && <i className="fas fa-arrow-left fa-1x text-green"></i>} <br /> </span>
                                                                                            <br />
                                                                                            {roleVal.description}
                                                                                        </p>
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="tab-pane fade" id="chat1" role="tabpanel" aria-labelledby="chat1-tab">
                                                                <Card.Title className='times-font'></Card.Title>
                                                                <section className="msger">
                                                                    <MessageList messages={this.state.chatMessages} currentUserPlayerID={this.props.currentUserPlayerID} gamePlayers={gamePlayers} />
                                                                    <MessageSendForm sendMessage={this.sendMessage.bind(this)} currentUserPlayerID={this.props.currentUserPlayerID} />
                                                                </section>
                                                            </div>
                                                            <div className="tab-pane fade" id="score1" role="tabpanel" aria-labelledby="score1-tab">
                                                                <Card.Title className='times-font'></Card.Title>
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='times-font'>Month</th>
                                                                            <th className='times-font'>Your Team</th>
                                                                            <th className='times-font'>Average</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.scoreDataAllTeams.map((scoreValue, scoreIndex) => {
                                                                            if (this.state.scoreDataMyTeam[scoreIndex] && this.state.scoreDataMyTeam[scoreIndex].roundNumber) {
                                                                                return (<tr key={scoreIndex}>
                                                                                    <td>{scoreValue._id}</td>
                                                                                    <td>
                                                                                        {
                                                                                            this.state.scoreDataMyTeam[scoreIndex] &&
                                                                                                this.state.scoreDataMyTeam[scoreIndex].roundNumber == scoreIndex + 1 ?
                                                                                                this.state.scoreDataMyTeam[scoreIndex].totalOfChips.toFixed(2) : 'n/a'
                                                                                        }
                                                                                    </td>
                                                                                    <td>{(scoreValue.totalOfChips / scoreValue.totalCountOffChips).toFixed(2)}</td>
                                                                                    {/* <td>{scoreValue.totalOfChips / scoreValue.totalCountOffChips}</td> */}
                                                                                </tr>)
                                                                            } else {
                                                                                return false
                                                                            }
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className={'container-fluid gameOuter'}>
                                <div className="row">
                                    <div onClick={this.onClickSidebar.bind(this)} className="canvas-open1">
                                        <i className="fa fa-angle-left"></i>
                                    </div>
                                    <div className="col-lg-11 col-xl-7  col-sm-12 col-12 ">
                                        <div className="orgKingOuter topInfoScroll">
                                            <p className="p-2">
                                                <span className="pull-left">
                                                    <strong className='times-font'>Organisation Name :</strong>
                                                    <span className='times-font'> {this.state.organisation ? this.state.organisation : 'n/a'}</span>
                                                </span>
                                                <span className="ml-5">
                                                    <strong className='times-font'>Playing as :</strong>
                                                    <span className='times-font'> {(currentUserCredentials && currentUserPlayerID != null) ? gamePlayers[currentUserPlayerID].name : 'n/a'}</span>
                                                </span>
                                                <span className="pull-right">
                                                    <strong className='times-font'>Team Name :</strong>
                                                    <span className='times-font'> {this.state.teamName ? this.state.teamName : 'n/a'}</span>
                                                </span>
                                            </p>
                                        </div>

                                        <div className="card text-white boardOuter mt-5" ref={(boardOuter) => { this.boardOuter = boardOuter }}>
                                            <div className={currentUserPlayerID + '' == ctx.currentPlayer + '' && currentUserCredentials ? ["boardImgOuter isMeJoined"] : ["boardImgOuter"]}>
                                                <span className="mobileVersion" >
                                                    {/* '../assets/images/79999 Inside out SwissRE Board master 1300x650.jpg' */}
                                                    <img className="card-img background-img" src={this.state.gameBoardImage ? this.apiBase + "/" + this.state.gameBoardImage : backgroundImgMobileDefault} style={{ minWidth: this.state.boardOuterHeight, height: this.state.boardOuterWidth }} />
                                                </span>
                                                <span>
                                                    {/* style={{ height: this.state.boardOuterHeight, width: this.state.boardOuterWidth }} */}
                                                    <img className="card-img background-img" src={this.state.gameBoardImage ? this.apiBase + "/" + this.state.gameBoardImage : backgroundImgMobileDefault} style={{ width: this.state.boardOuterHeight, height: this.state.boardOuterWidth }} />
                                                </span>
                                            </div>
                                            <div className="card-img-overlay">
                                                {G.cells.map((value, index) => {
                                                    // let arrElements = this.chipsRenderAccordingMove(index, value)
                                                    // arrElements.map((subValue, subIndex) => {
                                                    //     return subValue
                                                    // })
                                                    return this.chipsRenderAccordingMove(index, value)

                                                })}
                                            </div>
                                        </div>
                                        <div className="row hideInMobileNavOuter">
                                            <div className="col-md-12">
                                                <Card className="cardBottomMenu">
                                                    <Card.Footer>
                                                        <Nav className="nav-fill" variant="pills">
                                                            {ctx.playOrder.map((value, indx) => {
                                                                return (
                                                                    <Nav.Item key={indx}>
                                                                        <Nav.Link onClick={e => (currentUserPlayerID == indx && this.props.currentUserCredentials) ? null : this.joinGameByUser(indx, gamePlayers[indx].name)}
                                                                            style={{ 'color': 'white !important', fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}
                                                                            className={this.getTabColorstatus(indx)}
                                                                        >
                                                                            {gamePlayers[indx].name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            })}
                                                        </Nav>
                                                    </Card.Footer>
                                                </Card>
                                            </div>
                                            <div className="col-md-12 navtrnOuter">
                                                <div className="row">
                                                    <div className="col-lg-12 col-sm-6 col-6"><button className="playerTurnBtn times-font"> </button> Player Turn
                                            <button className="offlineBtn times-font ml-4"></button> Offline
                                            <button className="onlineBtn times-font ml-4"> </button> Online</div>
                                                    {/* <div className="col-lg-4 col-sm-6  col-6 times-font"><button className="nextPlayerAlertedBtn times-font"></button> Next Player Alerted</div> */}
                                                    {/* <div className="col-lg-5 col-sm-12 times-font">Click on role to see profile each other</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-sm-2 col-12 mt-2 showInMobile topRightCounter">
                                        <Card className="counterOuter">
                                            <Card.Body className="p-0">
                                                <div className="text-center" >
                                                    <div className="btn-group">
                                                        <button
                                                            onTouchStart={this.handleButtonPress.bind(this, false, 'long')}
                                                            onTouchEnd={this.handleButtonRelease}
                                                            onMouseDown={this.handleButtonPress.bind(this, false, 'long')}
                                                            onMouseUp={this.handleButtonRelease}
                                                            onMouseLeave={this.handleButtonRelease}
                                                            onClick={this.handleButtonPress.bind(this, false, 'short')}
                                                            style={{ height: '40px', width: '15%', }}
                                                            type="button" className="btn btn-primary">
                                                            <i className="fas fa-minus fa-1x"></i>
                                                        </button>
                                                        <input type="text" id="inputEstimatedBudget"
                                                            onChange={(e) => this.setState({ counterValue: e.target.value })} value={this.state.counterValue}
                                                            style={{ fontSize: '16px', width: '70%', height: '40px', margin: '0px' }} className="form-control text-center" />
                                                        <button
                                                            onTouchStart={this.handleButtonPress.bind(this, true, 'long')}
                                                            onTouchEnd={this.handleButtonRelease}
                                                            onMouseDown={this.handleButtonPress.bind(this, true, 'long')}
                                                            onMouseUp={this.handleButtonRelease}
                                                            onMouseLeave={this.handleButtonRelease}
                                                            onClick={this.handleButtonPress.bind(this, true, 'short')}
                                                            style={{ height: '40px', width: '15%', }}
                                                            type="button" className="btn btn-primary">
                                                            <i className="fas fa-plus fa-1x"></i>
                                                        </button>
                                                        <br />
                                                    </div>
                                                    {ctx.currentPlayer == currentUserPlayerID + '' && <button onClick={() => this.onChipDropped(null, null)} type="button" className="mt-2 ml-0 btn btn-success msger-send-btn">Play</button>}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 mt-5 mobileMrginTOP col-12 hideInMobileNavOuter ipadHide">
                                        <Card className="rightCardOuter mt-0" style={{ height: this.state.boardOuterHeight }}>
                                            <Card.Body className="p-2">
                                                <Nav variant="pills">
                                                    <div className="MoveTabOuter">
                                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                            <li className="nav-item">
                                                                <a className="nav-link active" id="roleCard-tab" data-toggle="tab" href="#roleCard" role="tab" aria-controls="roleCard" aria-selected="true">Role Card</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat" aria-selected="false">Chat</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link" id="score-tab" data-toggle="tab" href="#score" role="tab" aria-controls="score" aria-selected="false">Score</a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content" id="myTabContent">
                                                            {this.state.roleCardList.length > 0 &&
                                                                <div className="tab-pane fade show active" id="roleCard" role="roleCardPanel" aria-labelledby="roleCard-tab">
                                                                    <Card.Title className='times-font' style={{ textTransform: 'uppercase', paddingBottom: 15 }}>{gamePlayers[parseInt(ctx.currentPlayer)].name}</Card.Title>
                                                                    {
                                                                        !isDefaultRoleName ?
                                                                            <span> <p className="times-font"> {gamePlayers[ctx.currentPlayer].description}</p></span>
                                                                            :
                                                                            <span> <p className="times-font"> {gameMoves[ctx.currentPlayer]}</p></span>
                                                                    }
                                                                    {
                                                                        this.state.roleCardList.map((roleVal, roleIndex) => {
                                                                            if (roleVal.roleNameId && roleVal.roleNameId.id + "" == ctx.currentPlayer) {
                                                                                return (
                                                                                    <span key={roleIndex}>
                                                                                        <p className="times-font">
                                                                                            <span className="font-weight-bold" >Move {roleVal.moveNumber + 1 + ' '}
                                                                                                {ctx.numMoves == roleVal.moveNumber && <i className="fas fa-arrow-left fa-1x text-green"></i>} <br /> </span>
                                                                                            <br />
                                                                                            {roleVal.description}
                                                                                        </p>
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            <div className="tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                                                                <Card.Title className='times-font'></Card.Title>
                                                                <section className="msger">
                                                                    <MessageList messages={this.state.chatMessages} currentUserPlayerID={this.props.currentUserPlayerID} gamePlayers={gamePlayers} />
                                                                    <MessageSendForm sendMessage={this.sendMessage.bind(this)} currentUserPlayerID={this.props.currentUserPlayerID} />
                                                                </section>
                                                            </div>
                                                            <div className="tab-pane fade" id="score" role="tabpanel" aria-labelledby="score-tab">
                                                                <Card.Title className='times-font'></Card.Title>
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className='times-font'>Month</th>
                                                                            <th className='times-font'>Your Team</th>
                                                                            <th className='times-font'>Average</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {/* {this.state.scoreDataMyTeam.length == 0 && <tr key={'1'}><td colSpan={3}>{'No data found'}</td></tr>} */}
                                                                        {this.state.scoreDataAllTeams.map((scoreValue, scoreIndex) => {
                                                                            if (this.state.scoreDataMyTeam[scoreIndex] && this.state.scoreDataMyTeam[scoreIndex].roundNumber) {
                                                                                return (<tr key={scoreIndex}>
                                                                                    <td>{scoreValue._id}</td>
                                                                                    <td>
                                                                                        {
                                                                                            this.state.scoreDataMyTeam[scoreIndex] &&
                                                                                                this.state.scoreDataMyTeam[scoreIndex].roundNumber == scoreIndex + 1 ?
                                                                                                this.state.scoreDataMyTeam[scoreIndex].totalOfChips.toFixed(2) : 'n/a'
                                                                                        }
                                                                                    </td>
                                                                                    <td>{(scoreValue.totalOfChips / scoreValue.totalCountOffChips).toFixed(2)}</td>
                                                                                    {/* <td>{scoreValue.totalOfChips / scoreValue.totalCountOffChips}</td> */}
                                                                                </tr>)
                                                                            } else {
                                                                                return false
                                                                            }
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-lg-1 col-sm-2 col-2 mt-5 hideInMobile">
                                        <Card className="counterOuter">
                                            <Card.Body className="p-0">
                                                <div className="text-center" >
                                                    <div className="btn-group-vertical">
                                                        <button
                                                            onTouchStart={this.handleButtonPress.bind(this, true, 'long')}
                                                            onTouchEnd={this.handleButtonRelease}
                                                            onMouseDown={this.handleButtonPress.bind(this, true, 'long')}
                                                            onMouseUp={this.handleButtonRelease}
                                                            onMouseLeave={this.handleButtonRelease}
                                                            onClick={this.handleButtonPress.bind(this, true, 'short')}
                                                            style={{ height: '80px' }}
                                                            type="button"
                                                            className="btn btn-primary">
                                                            <i className="fas fa-plus fa-1x"></i>
                                                        </button>
                                                        <input type="text" id="inputEstimatedBudget" onChange={(e) => this.setState({ counterValue: e.target.value })} value={this.state.counterValue} style={{ fontSize: '16px', width: '100%', height: '50px', margin: '0px' }} className="form-control text-center" />
                                                        <button
                                                            onTouchStart={this.handleButtonPress.bind(this, false, 'long')}
                                                            onTouchEnd={this.handleButtonRelease}
                                                            onMouseDown={this.handleButtonPress.bind(this, false, 'long')}
                                                            onMouseUp={this.handleButtonRelease}
                                                            onMouseLeave={this.handleButtonRelease}
                                                            onClick={this.handleButtonPress.bind(this, false, 'short')}
                                                            style={{ height: '80px' }} type="button"
                                                            className="btn btn-primary">
                                                            <i className="fas fa-minus fa-1x"></i>
                                                        </button>
                                                    </div>
                                                    <br />
                                                    {ctx.currentPlayer == currentUserPlayerID + '' && <button onClick={() => this.onChipDropped(null, null)} type="button" className="mt-2 ml-0 btn btn-success msger-send-btn">Play</button>}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <Footer childProps={this.props} />
                        </div> :

                        <div style={{ position: 'fixed', height: '100%', width: '100%', alignContent: 'center' }}>
                            {'Connecting....'}
                        </div>
                }
            </LoadingOverlay>
        )
    }
}



const mapStateToProps = state => ({
    isPlayerLoggedIn: state.gameReducer.isPlayerLoggedIn,
    currentUserFromStore: state.userReducer.currentUser,
    gameDataFromStore: state.gameReducer.gameData,
    gamePlayers: state.gameReducer.gamePlayers,
    isMeJoined: state.gameReducer.isMeJoined,
    gameChats: state.gameReducer.gameChats,
    gameMoves: state.gameReducer.gameMoves,
    playerNames: state.gameReducer.playerNames,
    gameSecret: state.gameReducer.gameSecret,
    currentGameName: state.gameReducer.currentGameName,
    currentGameID: state.gameReducer.currentGameID,

    currentUserCredentials: state.gameReducer.currentUserCredentials,
    currentUserPlayerID: state.gameReducer.currentUserPlayerID,
    defaultRoleCards: state.gameReducer.defaultRoleCards,
    defaultRoleNames: state.gameReducer.defaultRoleNames


})

const mapDispatchToProps = dispatch => ({
    playerLogin: (islogin) => { dispatch(playerLogin(islogin)) },
    storeCurrentUser: (currentUser) => { dispatch(storeCurrentUser(currentUser)) },
    updateCurrentPlayerID: (playerid) => { dispatch(updateCurrentPlayerID(playerid)) },
    removeCurrentPlayerID: (playerid) => { dispatch(removeCurrentPlayerID(playerid)) },

    storeCurrentGameDetails: (gameData) => { dispatch(storeCurrentGameDetails(gameData)) },
    storeCurrentPlayerCredentials: (credentials) => { dispatch(storeCurrentPlayerCredentials(credentials)) },
    setGamePlayerToStore: (playerData) => { dispatch(setGamePlayerToStore(playerData)) },
    setNotificationToStore: (playerData) => { dispatch(setNotificationToStore(playerData)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Board)

// export default Board;