
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateTabNavigation } from '../store/actions'

import request from 'superagent';
// import { API_URL, API_PORT, IS_PRODUCTION } from '../constants'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';

// import { AgGridReact } from 'ag-grid-react';
import { AllModules } from 'ag-grid-react';
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

import { AgGridReact } from 'ag-grid-react';
import Popup from "reactjs-popup";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import CreatableSelect from 'react-select/creatable';

import ReactDataGrid from 'react-data-grid';
import 'react-data-grid/dist/react-data-grid.css';
import ReactPaginate from 'react-paginate';

class RoleCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            roleCardList: [],
            selectedRoleName: null,
            selectedMoveNumber: null,
            description: "",

            //ag-grid
            modules: AllModules,
            columnDefs: [
                {
                    field: 'organisation',
                    headerName: 'Organisation',
                    maxWidth: 150,
                    valueGetter(param) {
                        return param.data.organisationId ? param.data.organisationId.organisation : ''
                    },
                },
                {
                    field: 'roleNameId',
                    headerName: 'Role Name',
                    maxWidth: 150,
                    valueGetter(param) {
                        return param.data.roleNameId ? param.data.roleNameId.name : ''
                    },
                },
                {
                    field: 'moveNumber',
                    headerName: 'Move Number',
                    maxWidth: 150,
                    valueGetter(param) {
                        return param.data.moveNumber + 1
                    },
                },
                {
                    field: 'description',
                    headerName: 'Move Description',
                    cellStyle: { 'white-space': 'normal' }
                }
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 100,
                sortable: true,
                resizable: true,
                autoHeight: true
            },
            getRowHeight: function (params) {
                // assuming 50 characters per line, working how how many lines we need
                return 18 * (Math.floor(params.data.description.length / 50) + 1);
            },
            rowSelection: 'single',
            selectedRow: null,
            paginationPageSize: 10,
            rowData: [],
            offset: 0,
            perPage: 10,
            pageCount: 0,
            editorState: null,
            organisation: '',
            organisationList: [],
            selectedOrganisation: '',
            recentlyCreatedOrgUserId: '',
            roleNameList: [],
            isPopupOpen: false,
            actionType: null

        }
        this.onCreateRoleCard = this.onCreateRoleCard.bind(this)
        this.onAddEditRoleCard = this.onAddEditRoleCard.bind(this)
        this.onDeleteRoleCard = this.onDeleteRoleCard.bind(this)
        this.onEditRoleCard = this.onEditRoleCard.bind(this)

        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    componentDidMount() {
        this.loadRoleCards()
        this.loadOrganisations()
    }

    async loadOrganisations() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getOrganisations`)
        const response = r.body
        if (response.success) {
            const result = response.ResponsePacket
            let tempArr = []
            let selectedObj = null
            for (let i = 0; i < result.length; i++) {
                tempArr.push({
                    label: result[i].organisation,
                    value: result[i]._id,
                })

                if (this.state.recentlyCreatedOrgUserId == result[i].organisationUserId) {
                    selectedObj = { label: result[i].organisation, value: result[i]._id }
                }
            }
            this.setState({
                organisationList: tempArr,
                selectedOrganisation: selectedObj,
                organisation: selectedObj ? selectedObj.label : '',
                isLoading: false
            }, () => {
                console.log(this.state.organisationList)
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async loadRoleNames(orgID) {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getRoleName`)
            .send({
                organisationId: orgID
            })
        const response = r.body
        if (response.success) {
            let arr = response.ResponsePacket
            let tempArr = []
            if (arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    tempArr.push({ value: arr[i]._id, label: arr[i].name })
                }

            } else {
                tempArr = []
            }
            this.setState({
                roleNameList: tempArr,
                isLoading: false
            }, () => {
                console.log(this.state.roleNameList)
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async loadRoleCards() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getRoleCards`)
            // .send({
            //     offset: this.state.offset,
            //     limit: this.state.perPage
            // })
        const response = r.body
        if (response.success) {
            this.setState({
                roleCardList: response.ResponsePacket,
                rowData: response.ResponsePacket,
                // pageCount: Math.ceil(response.countDocuments / this.state.perPage),
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async onCreateRoleCard() {
        this.setState({
            isLoading: true
        })
        const r = await request
            .post(`${this.apiBase}/createRoleCard`)
            .send({
                organisationId: this.state.selectedOrganisation.value,
                roleNameId: this.state.selectedRoleName.value,
                moveNumber: this.state.selectedMoveNumber.value,
                description: this.state.description,
                isDeleted: false,
            })
        const response = r.body
        if (response.success) {
            this.setState({
                selectedRoleName: null,
                selectedMoveNumber: null,
                description: "",
                isLoading: false
            }, () => {
                this.loadRoleCards()
                confirmAlert({
                    title: 'Role Card',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'Role Card',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    async onEditRoleCard() {
        this.setState({
            isLoading: true
        })
        const r = await request
            .post(`${this.apiBase}/editRoleCard`)
            .send({
                _id: this.state.selectedRow._id,
                organisationId: this.state.selectedOrganisation.value,
                roleNameId: this.state.selectedRoleName.value,
                moveNumber: this.state.selectedMoveNumber.value,
                description: this.state.description,
                isDeleted: false,
            })
        const response = r.body
        if (response.success) {
            this.clearState()
            this.setState({
                selectedRoleName: null,
                selectedMoveNumber: null,
                description: "",
                isLoading: false,
                selectedRow : null,

            }, () => {
                this.loadRoleCards()
                confirmAlert({
                    title: 'Role Card',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'Role Card',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }
    
    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleImageUploadChange(event) {
        this.setState({
            gameBoard: URL.createObjectURL(event.target.files[0])
        })
    }

    onClickTab = (tabName) => {
        this.props.updateTabNavigation({ TabName: tabName })
    }

    handleChange = selectedRoleName => {
        this.setState(
            { selectedRoleName },
            () => console.log(`Option selected:`, this.state.selectedRoleName)
        )
    }

    handleChangeMoveNumber = selectedMoveNumber => {
        this.setState(
            { selectedMoveNumber },
            () => console.log(`Option selected:`, this.state.selectedMoveNumber)
        );
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            description: editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent())),
        })
    }

    onOrganisationChange = (newValue, actionMeta) => {
        if (actionMeta.action == "create-option") {
            this.setState({
                organisation: newValue.label
            }, () => {
                this.onCreateOrganisation()
            })
        } else if (actionMeta.action == "select-option") {
            this.setState({
                selectedOrganisation: newValue,
                organisation: newValue.label,
            }, () => {
                this.loadRoleNames(newValue.value)
            })
        }
    }

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage)
        this.setState({ offset: offset }, () => {
            this.loadRoleCards()
        })
    }

    onSelectionChanged = () => {
        var selectedRows = this.gridApi.getSelectedRows();
        this.setState({
            selectedRow: selectedRows && selectedRows.length > 0 ? selectedRows[0] : null
        })
    }

    clearState = () => {
        this.setState({
            selectedRow: null,
            isPopupOpen: false,
            organisationId: '',
            name: '',
            description: '',
            id: '',
            isDeleted: false,
        })
    }

    async onAddEditRoleCard(type) {
        const { selectedRow } = this.state
        if (!selectedRow && type == 'edit') {
            return
        } else {
            if (type == 'edit') {
                this.setState({
                    selectedOrganisation: {
                        label: selectedRow.organisationId.organisation,
                        value: selectedRow.organisationId._id,
                    },
                    selectedMoveNumber: {
                        value: selectedRow.moveNumber,
                        label: selectedRow.moveNumber + 1
                    },
                    selectedRoleName: {
                        value: selectedRow.roleNameId._id,
                        label: selectedRow.roleNameId.name
                    },
                    isPopupOpen: true,
                    organisationId: selectedRow._id,
                    name: selectedRow.name,
                    description: selectedRow.description,
                    id: selectedRow.id,
                    isDeleted: false,
                    actionType: 'Update Role Card'
                })
            } else {
                this.setState({
                    isPopupOpen: true,
                    organisationId: '',
                    name: '',
                    description: '',
                    id: '',
                    selectedOrganisation: null,
                    selectedSequence: null,
                    isDeleted: false,
                    actionType: 'Add Role Card'
                })
            }
        }
    }

    async onDeleteRoleCard() {
        const { selectedRow } = this.state
        confirmAlert({
            title: 'Delete RoleCard',
            message: "Are you sure you wants to delete the record : " + selectedRow.name + "?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        this.deleteRoleCard()
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {

                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async deleteRoleCard() {
        const { selectedRow } = this.state
        this.setState({ isLoading: true })
        const r = await request
            .post(`${this.apiBase}/deleteRoleCard`)
            .send({
                _id: selectedRow._id
            })
        const response = r.body
        if (response.success) {
            this.setState({
                name: "",
                organisationId: "",
                description: "",
                isLoading: false,
                selectedRow: null
            }, () => {
                this.loadRoleCards()
                confirmAlert({
                    title: 'RoleCard',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'RoleCard',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    render() {
        const { roleCardList, isLoading } = this.state
        return (
            <LoadingOverlay
                active={isLoading}
                spinner
            >
                <div className="card m-10">
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <div style={{ padding: '0 15px 15px', display: 'inline-block', width: '100%' }}>
                                        <button
                                            disabled={true}
                                            type="button"
                                            className="btn btn-danger float-right btn-sm"
                                            onClick={this.onDeleteRoleCard}
                                        >{'Delete Record'}</button>
                                        <button
                                            disabled={!this.state.selectedRow}
                                            type="button"
                                            className="btn btn-success float-right btn-sm mx-2"
                                            onClick={this.onAddEditRoleCard.bind(this, 'edit')}
                                        >{'Update Record'}</button>
                                        <button
                                            type="button"
                                            className="btn btn-success float-right btn-sm"
                                            onClick={this.onAddEditRoleCard.bind(this, 'add')}
                                        >{'Add New'}</button>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <div style={{ width: '985px', height: '550px' }}>
                                            <div
                                                id="myGrid"
                                                style={{
                                                    height: '550px',
                                                    width: '985px',
                                                }}
                                                className="ag-theme-alpine"
                                            >
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowSelection={this.state.rowSelection}
                                                    onGridReady={this.onGridReady}
                                                    rowData={this.state.rowData}
                                                    pagination={true}
                                                    enableCellTextSelection={true}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                    rowDeselection={true}
                                                />                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Popup open={this.state.isPopupOpen} closeOnDocumentClick={false}>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group align-center">
                                    <h3 className="text-center m-2">{this.state.actionType} </h3>
                                </div>
                                <div style={{ marginTop: '40px' }}>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="organisation">Organisation</label>
                                            <CreatableSelect
                                                isClearable
                                                value={this.state.selectedOrganisation}
                                                onChange={this.onOrganisationChange}
                                                onInputChange={this.handleInputChange}
                                                options={this.state.organisationList}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="roleName">Role Name/Player <span style={{fontSize:10}}> (Select player name, for example : Investor)</span></label>
                                            <Select
                                                // className="form-control"
                                                id={"roleName"}
                                                value={this.state.selectedRoleName}
                                                onChange={this.handleChange}
                                                options={this.state.roleNameList}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="moveNumber">Move Number</label>
                                            <Select
                                                // className="form-control"
                                                id={"moveNumber"}
                                                value={this.state.selectedMoveNumber}
                                                onChange={this.handleChangeMoveNumber}
                                                options={[
                                                    { value: 0, label: '1' },
                                                    { value: 1, label: '2' },
                                                    { value: 2, label: '3' },
                                                ]}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Move Description</label>
                                            <textarea onChange={this.onTextChange} id="description" value={this.state.description} className="form-control" />
                                            {/* <Editor
                                                editorState={this.state.editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: '0 15px 15px', display: 'inline-block', width: '100%' }}>
                                    <button
                                        type="button"
                                        className="btn btn-warning float-right btn-sm mx-1"
                                        onClick={() => {
                                            this.setState({ isPopupOpen: false }, () => {
                                                this.clearState()
                                            })
                                        }}
                                    >{'Cancel'}</button>
                                    <button
                                        disabled={!this.state.selectedRoleName || !this.state.selectedMoveNumber || !this.state.description}
                                        type="button"
                                        className="btn btn-success float-right btn-sm mx-1"
                                        onClick={this.state.actionType == 'Update Role Card' ? this.onEditRoleCard : this.onCreateRoleCard}
                                    >{'Submit'}</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = state => ({
    tabSettings: state.userReducer.tabSettings,
    roleCardList: state.userReducer.roleCardList,
})

const mapDispatchToProps = dispatch => ({
    updateTabNavigation: (data) => { dispatch(updateTabNavigation(data)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleCards)
