export const initialDefaultState = {
    currentUser: {
        Response: "",
        AppUserID: null,
        VendorID: null,
        UserTypeID: null,
        Email: "",
        UserType: "",
        Name: "Admin",
        ProfileImage: "",
        CustomerID: null,
        OutletID: null,
        OutletName: null,
        DeviceToken: "",
        VehicleRegNumber: "",
        IsFirstTime: false,
        IsActivated: false,
        Status: false,
        Message: ""
    },
    currentUserSetings: {
        SettingID: 1,
        Currency: "",
        DeliveryCharges: "",
        IsGST: "",
        GSTPercentage: "",
        IsLanguage2: "",
        Language2: "",
        Message: ""
    },
    tabSettings: {
        TabName: 'Dashboard',
    },
    teamList: [
        { name: 'TM-Infosys', users: '8', status: 'Active' },
    ],
    gameList: [
        { team: 'TM-infosys', id: 'AX-infosys', status: 'Active' },
    ],
    tabNames: ['Dashboard', 'RoleNames', 'RoleCard', 'Games','StaticPages', 'Settings'],
    gameData: {
        DT1: 0,
        DT2: 0,
        DT3: 0,
        DT4: 0,
        DT5: 0,
        DT6: 0,
        DT7: 0,
        DT8: 0
    },
    Players: [
        { id: 0, name: 'Investor', isJoined: false },
        { id: 1, name: 'Sales', isJoined: false },
        { id: 2, name: 'Markets', isJoined: false },
        { id: 3, name: 'Human Resources', isJoined: false },
        { id: 4, name: 'Head hunters', isJoined: false },
        { id: 5, name: 'Operations', isJoined: false },
        { id: 6, name: 'Customers', isJoined: false },
        { id: 7, name: 'Finance', isJoined: false },
    ],
    playerNames: {
        0: "Investor",
        1: "Sales",
        2: "Markets",
        3: "Human Resources",
        4: "Head hunters",
        5: "Operations",
        6: "Customers",
        7: "Finance",

    },

    gameSecret: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: ""
    },
    currentUserPlayerID: '0',
    numberOfPlayers: 8,
    isPlayerLoggedIn: true,
    // Investor
    // Sales
    // Markets
    // Human Resources
    // Head hunters
    // Operations
    // Customers
    // Finance

    DefaultRoleNames: [
        { id: 0, name: 'Investor', isJoined: false },
        { id: 1, name: 'Sales', isJoined: false },
        { id: 2, name: 'Markets', isJoined: false },
        { id: 3, name: 'Human Resources', isJoined: false },
        { id: 4, name: 'Head hunters', isJoined: false },
        { id: 5, name: 'Operations', isJoined: false },
        { id: 6, name: 'Customers', isJoined: false },
        { id: 7, name: 'Finance', isJoined: false },
    ],
    Moves: [
        "Your objective is to maximise your return on investment ensuring that by the year end, you have at least the value you started with (1000 units)."
        ,
        "Your objective is to generate demand by offering innovative products & services to your market."
        ,
        "Demand is influenced by the attractiveness of your products & services as well as by your reputation."
        ,
        "Your objective is to use feedback from your performance to engage and retain the talent necessary to sustain your growth."
        ,
        "Your objective is to maximise your fee income through the turnover of talent ensuring that by the year end, you have at least the value you started with (1000 units)."
        ,
        "Your objective is to grow reputation by ensuring you have the capability to deliver high quality products & services."
        ,
        "Reputation is influenced by your performance as well as by demand (social proofing)."
        ,
        "Your objective is to decide the mix of retained profit and investment necessary to sustain your growth."
    ],
    Chats: [
        " <strong>Stackholders :</strong> I am thinking of moving 25 to innovation and recovering 10 from investment -what do you think? </p> <p class=times-font> <strong>Retail :</strong> I would not recover anythink at the moment - we can do that later</p> <p class=times-font> <strong>Markets :</strong> I would be much bolder and invest a lot more! </p> <p class=times-font> <strong>Recruters :</strong> If you do that, then i will match you on my turn!"
    ],
    DefaultRoleCards: [{
        roleNameId: { id: 0 },
        moveNumber: 0,
        description: "Decide how much to invest in the budget. Enter this amount and press play. Note that the maximum you can invest is limited by the amount in profit."
    }, {
        roleNameId: { id: 0 },
        moveNumber: 1,
        description: "Decide the return on investment you would like to achieve from the profit generated. Enter this amount and press play."
    }, {
        roleNameId: { id: 1 },
        moveNumber: 0,
        description: "Decide the products & services necessary to grow demand. Enter this amount and press play."
    }, {
        roleNameId: { id: 1 },
        moveNumber: 1,
        description: "Decide the budget required for future innovation. Enter this amount and press play."
    }, {
        roleNameId: { id: 2 },
        moveNumber: 0,
        description: "Calculate the amount to add to, or remove from demand so that it matches the amount in products & services. Enter this amount and press play."
    }, {
        roleNameId: { id: 2 },
        moveNumber: 1,
        description: "Calculate the amount to add to, or remove from demand so that the difference between demand and reputation is halved. Enter this amount and press play."
    }, {
        roleNameId: { id: 3 },
        moveNumber: 0,
        description: "Compare performance with reputation. If the amount in performance is less than or equal to reputation, enter the amount and press play. If greater, enter the equivalent amount to that in reputation and press play. Engagement will increase accordingly with excess performance going to waste."
    }, {
        roleNameId: { id: 3 },
        moveNumber: 1,
        description: "Decide the number of engaged employees you wish to retain. Enter this amount and press play."
    }, {
        roleNameId: { id: 3 },
        moveNumber: 2,
        description: "Your reputation is making you attractive to millennials. Decide where they would make the greatest difference to the organisation by clicking on profit, innovation, engagement or capability. An equivalent of 20% of the value in reputation will be allocated accordingly."
    }, {
        roleNameId: { id: 4 },
        moveNumber: 0,
        description: "Decide how much talent to offer the business. Enter this amount and press play. Note that the maximum you can offer is limited by the amount in engagement."
    }, {
        roleNameId: { id: 4 },
        moveNumber: 1,
        description: "Decide how many employees to head hunt away from the business as talent to offer other clients. Enter this amount and press play."
    }, {
        roleNameId: { id: 5 },
        moveNumber: 0,
        description: "Decide the performance necessary to grow your reputation. Enter this amount and press play."
    }, {
        roleNameId: { id: 5 },
        moveNumber: 1,
        description: "Decide the capability required to continuously improve your performance in the future. Enter this amount and press play."
    }, {
        roleNameId: { id: 6 },
        moveNumber: 0,
        description: "Calculate the amount to add to, or remove from reputation so that it matches the amount in performance. Enter this amount and press play."
    }, {
        roleNameId: { id: 6 },
        moveNumber: 1,
        description: "Calculate the amount to add to, or remove from reputation so that the difference between reputation and demand is halved. Enter this amount and press play."
    }, {
        roleNameId: { id: 7 },
        moveNumber: 0,
        description: "Compare products & services with demand. If the amount in products & services is less than or equal to demand, enter the amount and press play. If greater, enter the equivalent amount to that in demand and press play. Profit will increase accordingly with excess products & services going to waste."
    }, {
        roleNameId: { id: 7 },
        moveNumber: 1,
        description: "Decide the amount of profit you wish to retain. Enter this amount and press play."
    }, {
        roleNameId: { id: 7 },
        moveNumber: 2,
        description: "Demand for your products & services is enabling you to increase prices. Decide where the resulting funds would make the greatest difference by clicking on profit, innovation, engagement or capability. An equivalent of 20% of the value in demand will be allocated accordingly."
    }]
}