import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
export default class ContactUs extends Component {

    render() {
        return (
            <div className="mainOuterDiv" >
                <Header childProps={this.props} />
                <section className="match-section set-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-form">
                                    <div className="section-title">
                                        <h3>Contact <span> Us</span></h3>
                                    </div>
                                    <form action="#">
                                        <div className="group-in">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" id="name" />
                                        </div>
                                        <div className="group-in">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" id="email" />
                                        </div>
                                        <div className="group-in">
                                            <label htmlFor="email">Subject</label>
                                            <input type="text" id="Subject" />
                                        </div>
                                        <div className="group-in">
                                            <label htmlFor="message">Message</label>
                                            <textarea id="message"></textarea>
                                        </div>
                                        <button type="submit">Submit Now</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-info">
                                    <div className="section-title">
                                        <h3>Contact <span> Info</span></h3>
                                    </div>
                                    <p> Please contact <a href="maiilto:support@insideoutpartnership.co.uk"> support@insideoutpartnership.co.uk</a> or call +44 7968 008818 should you have any questions or require support </p>
                                    <div className="ci-address">
                                        <h5>London Office</h5>
                                        <ul>
                                            {/* <li>consectetur adipisicing elit, sed do eiusmod, London</li> */}
                                            <li>Parkside<br />
                                             Seale Hill<br />
                                             Reigate<br />
                                             Surrey<br />
                                             United Kingdom<br />
                                             RH2 8HZ </li>
                                            <li>+44 7968 008818</li>
                                            <li> <a href="maiilto:support@insideoutpartnership.co.uk"> support@insideoutpartnership.co.uk</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer childProps={this.props} />
            </div>
        )
    }
}