import React, { Component } from 'react';
import { connect } from 'react-redux'

class Header extends Component {
    logout = () => {
        localStorage.setItem('adminLogin', false);
        window.location.reload();
    }
    render() {
        const { currentUserFromStore, tabSettings } = this.props


        return (
            <div className="wrapper">


                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#!" role="button"><i className="fas fa-bars"></i></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a className="nav-link">{tabSettings.TabName}</a>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#!">
                                <i className="fas fa-th-large"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">{currentUserFromStore.Name}</span>
                                <div className="dropdown-divider"></div>
                                <div className="dropdown-divider"></div>
                                <a href="#!" onClick={this.logout} className="dropdown-item dropdown-footer">Logout</a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    currentUserFromStore: state.userReducer.currentUser,
    tabSettings: state.userReducer.tabSettings,
})

// const mapDispatchToProps = dispatch => ({
//     updateTabNavigation: (data) => { dispatch(updateTabNavigation(data)) },
// })

export default connect(
    mapStateToProps,
    null
)(Header)