import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import userReducer from '../store/reducers/user.reducer'
import gameReducer from '../store/reducers/game.reducer'

const rootReducer = combineReducers({
    userReducer,
    gameReducer
})
const configureStore = () => {
    return createStore(rootReducer, applyMiddleware(thunk));
}
export default configureStore;