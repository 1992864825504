import React, { Component } from 'react';
import bannerImg from '../assets/images/banner-bg.svg'
import Header from './Header';
import Footer from './Footer';
export default class Support extends Component {

    render() {
        return (
            <div className="mainOuterDiv" >
                <Header childProps={this.props} />
                <section className="match-section set-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="section-title">
                                <h3><span>Support</span></h3>
                            </div>
                            <h2>Investor22</h2>
                            <p>Objective Your objective is to maximise your return on investment ensuring that by the game end, you have at least the value you started with (1000 units).</p>
                            <p>Move 1 Decide how much to invest in the budget. Enter this amount and press play. Note that the maximum you can invest is limited by the amount in profit.</p>
                            <p>Move 2 Decide the return on investment you would like to achieve from the profit generated. Enter this amount and press play.</p>
                            <h2>Sales</h2>
                            <p>Objective Your objective is to generate demand by offering innovative products & services to your market.</p>
                            <p>Move 1 Decide the products & services necessary to grow demand. Enter this amount and press play.</p>
                            <p>Move 2 Decide the budget required for future innovation. Enter this amount and press play.</p>
                            <h2>Markets</h2>
                            <p>Rationale Demand is influenced by the attractiveness of your products & services as well as by your reputation.</p>
                            <p>Move 1 Calculate the amount to add to or remove from demand so that it matches the amount in products & services. Enter this amount and press play.</p>
                            <p>Move 2 Calculate the amount to add to or remove from demand so that the difference between demand and reputation is halved. Enter this amount and press play.</p>
                            <h2>Human Resources</h2>
                            <p>Objective Your objective is to motivate, retain and hire the talent necessary to sustain your growth </p>
                            <p>Move 1 If the amount in quality of delivery is less than or equal to that in reputation, enter the amount in quality and press play. If greater, enter the equivalent amount and press play. Engagement will increase accordingly with excess quality going to waste.</p>
                            <p>Move 2 Decide the number of engaged employees you wish to retain. Enter this amount and press play.</p>
                            <p>Move 3 Your reputation is attracting talent to join your organisation. Calculate 10% of the value in reputation. Enter this amount and press play. Engagement will increase accordingly.</p>
                            <h2>Head hunters</h2>
                            <p>Objective Your objective is to maximise your fee income through the turnover of talent ensuring that by the year end, you have at least the value you started with (1000 units).</p>
                            <p>Move 1 Decide how much talent to offer the business. Enter this amount and press play. Note that the maximum you can offer is limited by the amount in engagement.</p>
                            <p>Move 2 Decide how many employees to head hunt away from the business as talent to offer other clients. Enter this amount and press play.</p>
                            <h2>Operations </h2>
                            <p>Objective Your objective is to grow reputation by ensuring you have the capability to deliver high quality products and services. </p>
                            <p>Move 1 Decide the quality of delivery necessary to grow your reputation. Enter this amount and press play.</p>
                            <p>Move 2 Decide the capability required to continuously improve your quality of delivery in the future. Enter this amount and press play.</p>
                            <h2>Customers</h2>
                            <p>Rationale Reputation is influenced by the quality of your delivery as well as by demand (social proofing).</p>
                            <p>Move 1 Calculate the amount to add to or remove from reputation so that it matches the amount in quality of delivery. Enter this amount and press play.</p>
                            <p>Move 2 Calculate the amount to add to or remove from reputation so that the difference between reputation and demand is halved. Enter this amount and press play.</p>
                            <h2>Finance</h2>
                            <p>Objective Your objective is to decide the mix of retained profit and investment necessary to sustain your growth.</p>
                            <p>Move 1 If the amount in products & services is less than or equal to that in demand, enter the amount in products & services and press play. If greater, enter the equivalent amount and press play. Profit will increase accordingly with excess products & services going to waste.</p>
                            <p>Move 2 Decide the amount of profit you wish to retain. Enter this amount and press play.</p>
                            <p>Move 3 Demand for your products & services is enabling you to increase prices. Calculate 10% of the value in demand. Enter this amount and press play. Your profit will increase accordingly.</p>
                        </div>
                    </div>
                </section>
                <Footer childProps={this.props} />
            </div>
        )
    }
}