import React, { Component } from 'react';
import { connect } from 'react-redux'
import { createNewGame } from '../store/actions'
import { bindActionCreators } from 'redux';
import request from 'superagent';
// import { API_URL, IS_PRODUCTION, API_PORT } from '../constants'
import Stepper from 'react-js-stepper'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import CreatableSelect from 'react-select/creatable';
import LoadingOverlay from 'react-loading-overlay';

// import { AgGridReact } from 'ag-grid-react';
import { AllModules } from 'ag-grid-react';
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

import { AgGridReact } from 'ag-grid-react';
import Popup from "reactjs-popup";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const steps = [{ title: 'Team Creation' }, { title: 'Game Preview & Publish' },]

var myCellRenderer = function (params) {
    return '<span style="color: ' + params.color + '">' + params.value + '</span>';
}

class Games extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            gameID: "",
            gameName: 'Collaboration-Game',
            creating: false,
            created: false,
            model: null,
            gameList: [],
            // playerNames: {
            //     0: "Investor",
            //     1: "Sales",
            //     2: "Markets",
            //     3: "Human Resources",
            //     4: "Head hunters",
            //     5: "Operations",
            //     6: "Customers",
            //     7: "Finance",
            // },
            // playerCount: 8,
            activeStep: 1,
            teamUserId: '',
            organisationUserId: '',
            teamName: '',
            organisation: '',
            uploadedImage: null,
            organisationList: [],
            selectedOrganisation: '',
            recentlyCreatedOrgUserId: '',
            uploadedImageSend: null,

            //ag-grid
            modules: AllModules,
            columnDefs: [
                {
                    field: 'organisationName',
                    headerName: 'Organisation',
                    valueGetter: function (param) {
                        return param.data.organisationId.organisation
                    },
                },
                {
                    field: 'teamUserId',
                    headerName: 'Team User Id',
                },
                {
                    field: 'teamName',
                    headerName: 'Team Name',
                },
                {
                    field: 'roundNumber',
                    headerName: 'Round Played',
                    valueGetter: function (param) {
                        return param.data.roundNumber > 0 ? param.data.roundNumber : '--'
                    },
                },
                {
                    field: 'created_at',
                    headerName: 'CreatedAt',
                    valueGetter: function (param) {
                        return new Date(param.data.created_at).toLocaleDateString()
                    }
                },
                {
                    field: 'gameBoardImage',
                    headerName: 'Board Image',
                    cellRenderer: param => {
                        var returnImg = ''
                        if (param.data.gameBoardImage) {
                            returnImg = document.createElement('img');
                            returnImg.innerHTML = param.data.gameBoardImage
                            returnImg.src = this.apiBase + "/" + param.data.gameBoardImage
                            returnImg.width = 60
                            returnImg.height = 30
                        } else {
                            returnImg = document.createElement('img');
                            returnImg.innerHTML = param.data.gameBoardImage
                            returnImg.width = 60
                            returnImg.height = 30
                        }
                        return returnImg;
                    }
                },
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 50,
            },
            paginationPageSize: 10,
            rowSelection: 'single',
            selectedRow: null,
            rowData: [],
            isPopupOpen: false,
            actionType: null,
            uploadedBoardImage: null
        }
        this.handleImageUploadChange = this.handleImageUploadChange.bind(this)
        this.onCreateGame = this.onCreateGame.bind(this)

        this.onAddEditGame = this.onAddEditGame.bind(this)
        this.onDeleteGame = this.onDeleteGame.bind(this)
        this.onEditGame = this.onEditGame.bind(this)
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`

    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    componentDidMount() {
        console.log("*******************", process.env)
        this.loadGames()
        this.loadOrganisations()
    }

    async loadOrganisations() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getOrganisations`)
        const response = r.body
        if (response.success) {
            const result = response.ResponsePacket
            let tempArr = []
            let selectedObj = null
            for (let i = 0; i < result.length; i++) {
                tempArr.push({
                    label: result[i].organisation,
                    value: result[i]._id,
                })

                if (this.state.recentlyCreatedOrgUserId == result[i].organisationUserId) {
                    selectedObj = { label: result[i].organisation, value: result[i]._id }
                }
            }
            this.setState({
                organisationList: tempArr,
                selectedOrganisation: selectedObj,
                organisation: selectedObj ? selectedObj.label : '',
                isLoading: false
            }, () => {
                console.log(this.state.organisationList)
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async loadGames() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getGames`);
        const response = r.body;
        if (response.success) {
            this.setState({
                gameList: response.ResponsePacket,
                rowData: response.ResponsePacket,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async onCreateOrganisation() {
        this.setState({
            isLoading: true,
            recentlyCreatedOrgUserId: ''
        })
        const r = await request
            .post(`${this.apiBase}/createOrganisation`)
            .send({
                organisationUserId: this.state.organisation.split(" ")[0].toLocaleLowerCase() + Math.floor(1000 + Math.random() * 9000),
                organisation: this.state.organisation,
                gameBoard: "",
                address: "",
                country: "",
                postCode: "",
                isActive: true,
                isDeleted: false
            })
        const response = r.body
        if (response.success) {
            this.setState({
                organisationUserId: "",
                recentlyCreatedOrgUserId: response.ResponsePacket.organisationUserId,
                organisation: "",
                gameBoard: "",
                isLoading: false

            }, () => {

                this.loadOrganisations()
                confirmAlert({
                    title: 'Organisation Created',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async onCreateGame() {
        const { selectedOrganisation, password, teamName, uploadedBoardImage } = this.state
        this.setState({
            isLoading: true
        })
        const r = await request
            .post(`${this.apiBase}/createGame`)
            .send({
                teamUserId: teamName.split(" ")[0].toLocaleLowerCase() + Math.floor(1000 + Math.random() * 9000), //by this user can login
                teamName: teamName,
                organisationId: selectedOrganisation.value,
                password: password,
                gameBoardImage: uploadedBoardImage,
                gameId: "", //this is loby game created id
                players: 8,
                isActive: true,
                isDeleted: false
            })

        const response = r.body
        if (response.success) {
            this.setState({
                name: "",
                teamUserId: "", //by this user can login
                teamName: "",
                organisationId: "",
                password: "",
                gameId: "", //this is loby game created id
                players: 8,
                isActive: true,
                isDeleted: false,
                activeStep: 1,
                organisation: '',
                uploadedImage: null,
                selectedOrganisation: '',
                uploadedBoardImage: null
            }, () => {
                this.clearState()
                this.loadGames()
                confirmAlert({
                    title: 'Game Created',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'Game Create Error',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    async onEditGame() {
        const { selectedOrganisation, password, teamName, selectedRow } = this.state
        this.setState({
            isLoading: true
        })
        const r = await request
            .post(`${this.apiBase}/editGame`)
            .send({
                _id: selectedRow._id,
                // teamUserId: teamName.split(" ")[0].toLocaleLowerCase() + Math.floor(1000 + Math.random() * 9000), //by this user can login
                teamName: teamName,
                organisationId: selectedOrganisation.value,
                password: password,
                // gameId: "", //this is loby game created id
                // players: 8,
                // isActive: true,
                // isDeleted: false
            })

        const response = r.body

        if (response.success) {
            this.setState({
                name: "",
                teamUserId: "", //by this user can login
                teamName: "",
                organisationId: "",
                password: "",
                gameId: "", //this is loby game created id
                players: 8,
                isActive: true,
                isDeleted: false,
                activeStep: 1,
                organisation: '',
                uploadedImage: null,
                selectedOrganisation: '',
                selectedRow: null
            }, () => {
                this.clearState()
                this.loadGames()
                confirmAlert({
                    title: 'Game Updated',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'Game update Error',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleOnClickStepper = (step) => {
        this.setState({ activeStep: step });
    }

    handleOnClickNext = () => {
        let nextStep = this.state.activeStep + 1;
        this.setState({ activeStep: nextStep })
    }

    handleOnClickBack = () => {
        let prevStep = this.state.activeStep - 1;
        this.setState({ activeStep: prevStep })
    }

    async handleImageUploadChange(event) {
        let imgSize = event.target.files[0]
        let img = new Image()
        img.src = window.URL.createObjectURL(event.target.files[0])

        // if (img.width > 100 || img.height > 100) {
        if (imgSize.type == "image/png" || imgSize.type == "image/jpeg") {
            this.uploadBoardImage(event)
        } else {
            confirmAlert({
                title: 'Warning',
                message: "Board image should be jpeg or png type in format.",
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {

                        }
                    }
                ],
                closeOnClickOutside: false,
                closeOnEscape: false
            })
            return false;
        }
        // img.onload = () => {
        //     // alert(img.width + " " + img.height);
        //     if (img.width > 100 || img.height > 100) {
        //         confirmAlert({
        //             title: 'Warning',
        //             message: "Height and Width must not exceed 100px.",
        //             buttons: [
        //                 {
        //                     label: 'Ok',
        //                     onClick: () => {

        //                     }
        //                 }
        //             ],
        //             closeOnClickOutside: false,
        //             closeOnEscape: false
        //         })
        //         return false;
        //     } else {
        //         this.uploadBoardImage(event)
        //     }
        // }
    }

    async uploadBoardImage(event) {
        this.setState({
            isLoading: true
        })

        // let formData = new FormData()
        // formData.append("file", event.target.files[0])

        const r = await request.post(`${this.apiBase}/uploadFile`).attach('imageFile', event.target.files[0])
        const response = r.body

        if (response.success) {
            this.setState({
                uploadedBoardImage: response.ResponsePacket,
                isLoading: false,
            }, () => {
                confirmAlert({
                    title: 'Game Board Upload',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                uploadedBoardImage: null,
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'Warning',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    onOrganisationChange = (newValue, actionMeta) => {

        if (actionMeta.action == "create-option") {
            this.setState({
                organisation: newValue.label
            }, () => {
                this.onCreateOrganisation()
            })
        } else if (actionMeta.action == "select-option") {
            this.setState({
                selectedOrganisation: newValue,
                organisation: newValue.label,
            })
        }
    }

    clearState = () => {
        this.setState({
            selectedRow: null,
            isPopupOpen: false,
            organisationId: '',
            name: '',
            description: '',
            id: '',
            isDeleted: false,
            activeStep: 1,
        })
    }

    async onAddEditGame(type) {
        const { selectedRow } = this.state
        if (!selectedRow && type == 'edit') {
            return
        } else {
            if (type == 'edit') {
                this.setState({
                    selectedOrganisation: {
                        label: selectedRow.organisationId.organisation,
                        value: selectedRow.organisationId._id,
                    },
                    organisation: selectedRow.organisationId.organisation,
                    isPopupOpen: true,
                    // organisationId: selectedRow._id,
                    teamName: selectedRow.teamName,
                    password: selectedRow.password,
                    uploadedBoardImage: selectedRow.gameBoardImage,

                    isDeleted: false,
                    actionType: 'Update Game'
                })
            } else {
                this.setState({
                    isPopupOpen: true,
                    organisationId: '',
                    organisation: null,
                    teamName: null,
                    name: '',
                    password: '',
                    description: '',
                    id: '',
                    uploadedBoardImage: null,
                    selectedOrganisation: null,
                    selectedSequence: null,
                    isDeleted: false,
                    actionType: 'Add Game'
                })
            }
        }
    }

    async onDeleteGame() {
        const { selectedRow } = this.state
        confirmAlert({
            title: 'Delete Game',
            message: "Are you sure you wants to delete the record : " + selectedRow.teamUserId + "?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        this.deleteGame()
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {

                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async deleteGame() {
        const { selectedRow } = this.state
        this.setState({ isLoading: true })
        const r = await request
            .post(`${this.apiBase}/deleteGame`)
            .send({
                _id: selectedRow._id
            })
        const response = r.body
        if (response.success) {
            this.setState({
                name: "",
                organisationId: "",
                description: "",
                isLoading: false,
                selectedRow: null
            }, () => {
                this.loadGames()
                confirmAlert({
                    title: 'Game',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'Game',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    onSelectionChanged = () => {
        var selectedRows = this.gridApi.getSelectedRows();
        this.setState({
            selectedRow: selectedRows && selectedRows.length > 0 ? selectedRows[0] : null
        })
    }

    render() {
        const { gameList, isLoading } = this.state
        return (
            <LoadingOverlay active={isLoading} spinner >
                <div className="card m-10">
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <div style={{ padding: '0 15px 15px', display: 'inline-block', width: '100%' }}>
                                        <button
                                            // disabled={true}
                                            disabled={!this.state.selectedRow}
                                            type="button"
                                            className="btn btn-danger float-right btn-sm"
                                            onClick={this.onDeleteGame}
                                        >{'Delete Record'}</button>
                                        <button
                                            disabled={!this.state.selectedRow}
                                            type="button"
                                            className="btn btn-success float-right btn-sm mx-2"
                                            onClick={this.onAddEditGame.bind(this, 'edit')}
                                        >{'Update Record'}</button>
                                        <button
                                            type="button"
                                            className="btn btn-success float-right btn-sm"
                                            onClick={this.onAddEditGame.bind(this, 'add')}
                                        >{'Add New'}</button>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <div style={{ width: '100%', height: '550px' }}>
                                            <div
                                                id="RoleNameGrid"
                                                style={{
                                                    height: '550px',
                                                    width: '100%',
                                                }}
                                                className="ag-theme-alpine"
                                            >
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={this.state.columnDefs}
                                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    defaultColDef={this.state.defaultColDef}
                                                    enableCellTextSelection={true}
                                                    // suppressRowClickSelection={true}
                                                    // groupSelectsChildren={true}
                                                    rowSelection={this.state.rowSelection}
                                                    // rowGroupPanelShow={this.state.rowGroupPanelShow}
                                                    // pivotPanelShow={this.state.pivotPanelShow}
                                                    // enableRangeSelection={true}
                                                    onGridReady={this.onGridReady}
                                                    rowData={this.state.rowData}
                                                    pagination={true}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    // cacheBlockSize={this.state.cacheBlockSize}
                                                    // cellClicked={(event) => this.onCellClicked(event)}
                                                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                    rowDeselection={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Popup open={this.state.isPopupOpen} closeOnDocumentClick={false} repositionOnResize>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group align-center">
                                    <h3 className="text-center m-2">{this.state.actionType} </h3>
                                </div>
                                <Stepper
                                    steps={steps}
                                    activeStep={this.state.activeStep}
                                    onSelect={this.handleOnClickStepper}
                                    showNumber={false}
                                />
                                <div style={{ marginTop: '20px' }}>
                                    {this.state.activeStep === 1 ?
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="teamName">Team Name</label>
                                                <input type="text" onChange={this.onTextChange} id="teamName" value={this.state.teamName} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password</label>
                                                <input type="password" onChange={this.onTextChange} id="password" value={this.state.password} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="organisation">Organisation</label>
                                                <CreatableSelect
                                                    isClearable
                                                    value={this.state.selectedOrganisation}
                                                    onChange={this.onOrganisationChange}
                                                    onInputChange={this.handleInputChange}
                                                    options={this.state.organisationList}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputFile">Upload Game Board</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="exampleInputFile" onChange={this.handleImageUploadChange.bind(this)} />
                                                        <label className="custom-file-label" htmlFor="exampleInputFile">Choose Game Board Image (W:1024, H:1024)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                {this.state.uploadedBoardImage &&
                                                    <span className="input-group-text" >
                                                        <img src={this.apiBase + '/' + this.state.uploadedBoardImage} style={{ width: '70px', height: '50px' }} />
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="card-body">
                                            <div className="card-body">
                                                {/* <strong><i className="fas fa-book mr-1"></i> Team ID</strong>
                                                <p className="text-muted">{this.state.teamName.split(' ')[0]}</p>
                                                <hr /> */}
                                                <strong><i className="fas fa-pencil-alt mr-1"></i> Team Name</strong>
                                                {this.state.teamName && <p className="text-muted">{this.state.teamName}</p>}
                                                <hr />
                                                <strong><i className="fas fa-map-marker-alt mr-1"></i> Organisation</strong>
                                                <p className="text-muted">{this.state.organisation}</p>
                                                <hr />
                                                <strong><i className="far fa-file-alt mr-1"></i> Game Board</strong>
                                                <div className="form-group">
                                                    {this.state.uploadedBoardImage &&
                                                        <span className="input-group-text" id="" >
                                                            <img src={this.apiBase + '/' + this.state.uploadedBoardImage} style={{ width: '70px', height: '50px' }} />
                                                        </span>
                                                    }
                                                    {!this.state.uploadedBoardImage &&
                                                        <p className="text-muted">{'Gameboard not available..'}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div style={{ padding: '0 15px 15px', display: 'inline-block', width: '100%' }}>
                                    <button
                                        type="button"
                                        className="btn btn-warning float-right btn-sm mx-1"
                                        onClick={() => {
                                            this.setState({ isPopupOpen: false }, () => {
                                                this.clearState()
                                            })
                                        }}
                                    >{'Cancel'}</button>
                                    {this.state.activeStep === 1 && <button
                                        type="button"
                                        disabled={!this.state.selectedOrganisation || !this.state.teamName || !this.state.password || !this.state.uploadedBoardImage}
                                        className="btn btn-success float-right btn-sm mx-1"
                                        onClick={this.handleOnClickNext}
                                    >{'Next'}</button>}

                                    {this.state.activeStep === 2 && <button
                                        type="button"
                                        disabled={!this.state.selectedOrganisation || !this.state.teamName || !this.state.password || !this.state.uploadedBoardImage}
                                        className="btn btn-success float-right btn-sm mx-1"
                                        onClick={this.state.actionType == 'Update Game' ? this.onEditGame : this.onCreateGame}
                                    >{'Publish'}</button>}
                                    {this.state.activeStep === 1 ? '' :
                                        <button
                                            type="button"
                                            className="btn btn-warning float-right btn-sm mx-1"
                                            style={{ marginRight: '10px' }}
                                            onClick={this.handleOnClickBack} >Back</button>}
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = state => {
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createNewGame: createNewGame }, dispatch);
}

export default connect(
    null,
    mapDispatchToProps
)(Games)