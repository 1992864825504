import React, { Component } from 'react';
import bannerImg from '../assets/images/banner-bg.svg'
import Header from './Header';
import Footer from './Footer';

import { connect } from 'react-redux'
import request from 'superagent';
import { API_URL } from '../constants'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
export default class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {
            gameName: 'Collaboration-Game',
            teamname: '',
            password: '',
            gameList: []
        }
    }

    onLoginClick = () => {
        if (this.state.teamname && this.state.password) {
            this.loginWithTeam()
        } else {
            this.showAlert("Credientials can't blank !!")
        }
    }

    showAlert = (msg) => {
        confirmAlert({
            title: 'Login',
            message: msg,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        // this.props.undo()
                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async loginWithTeam() {
        const r = await request.post(`${API_URL}/api/loginWithTeam`)
            .send({
                userName: this.state.teamname,
                password: this.state.password
            })

        const response = r.body
        if (response.success) {
            this.setState({
                gameList: response.ResponsePacket
            }, () => {

            })
        } else {
            this.showAlert(response.message)
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    render() {
        return (
            <div className="mainOuterDiv" >
                <Header childProps={this.props} />
                <section className="hero-section set-bg" >
                    <img src={bannerImg} />
                    <div className="BannerContentOuter" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="hs-item">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="hs-text">
                                                        <h4>Enter your login details including your username and password</h4>
                                                        <h2>The Collaboration Game </h2>
                                                        <a className="primary-btn">Login</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>              
                <section className="match-section set-bg" >
                    <div className="container">
                        <div className="row">
                            <div className="section-title">
                                <h3>About <span> Game</span></h3>
                            </div>
                            <p> The Collaboration Game is a business simulation designed to improve collaboration between individuals and teams in your organisation. In playing the game, you will experience how your individual and collective success is either reinforced or undermined by the way in which you collaborate. You will learn that in order to be successful in the game, and in your organisation, you must view the organization from each other's perspectives, understand the interdependencies, communicate across functions, collectively resolve issues and make "joined up" decisions.</p>
                            <p> The game is played in multiples of 8 players with each person responsible for one or more decisions that collectively determine the result. The decisions associated with each role are described by role descriptions that closely reflect those of your organisation. Value is added or wasted depending on the quality of interaction and decision making between the players. The game is played in a series of “rounds” each of which represents a trading quarter in the life of the organisation. You may find yourself competing against other teams in your organisation. However, whilst your own score is important, it is your average score as a whole organisation that counts! </p>
                        </div>
                    </div>
                </section>
                <Footer childProps={this.props} />
            </div>
        )
    }
}