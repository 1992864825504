import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <footer className="main-footer">
                <span>Copyright &copy; 2020-2021</span>
                <div className="float-right d-none d-sm-inline-block">
                    <span>v1.0</span>
                </div>
            </footer>
        )
    }
}