import React from 'react';
import * as game from './game'

export const CollaborationGame = {

  name: "Collaboration-Game",

  setup: () => ({
    players: {
      '0': 'Investor',
      '1': 'Sales',
      '2': 'Markets',
      '3': 'Human Resources',
      '4': 'Head hunters',
      '5': 'Operations',
      '6': 'Customers',
      '7': 'Finance',
    },
    cells: [1000, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 1000, 0, 5000]
  }),

  moves: {
    clickCell(G, ctx, arr) {
      G.cells = arr
    }
  },

  turn: {
    moveLimit: 3
  },

  ai: {
    enumerate: G => {
      let moves = [];
      for (let i = 0; i < 14; i++) {
        if (G.cells[i] === null) {
          moves.push({ move: "clickCell", args: [i] });
        }
      }
      return moves;
    }
  }
}