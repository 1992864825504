import React from 'react'
import { DropTarget } from 'react-dnd'
import CountUp from 'react-countup'

const style = {
    height: '12rem',
    width: '12rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    color: 'white',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
}

const Chips = ({ canDrop, isOver, connectDropTarget, dynamicClass, chipValue, chipIndex, prefix, dynamicStyle, openDialog, dynamicBGColor, manualDropHelighted }) => {
    const isActive = canDrop && isOver
    let backgroundColor = dynamicBGColor
    let customStyle = { backgroundColor }

    if (isActive) {
        customStyle = { backgroundColor: '#0f5c23', height: '70px', width: '70px', }
    } else if (canDrop) {
        customStyle = { backgroundColor: '#8b0000' }
    } else if (manualDropHelighted) {
        customStyle = { backgroundColor: '#8b0000' }
    }
    return (
        <div ref={connectDropTarget} style={customStyle} className={dynamicClass} onClick={() => openDialog(chipIndex)}>
            <CountUp
                redraw={false}
                prefix={prefix}
                end={chipValue}
                style={dynamicStyle}
            />
        </div>
    )
}
export default DropTarget(
    'CHIP',
    {
        drop: (props) => ({ chipIndex: props.chipIndex }),
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
)(Chips)
