import React, { Component } from 'react';
import { connect } from 'react-redux'

import Dashboard from '../adminPanel/Dashboard'
import Games from '../adminPanel/Games'
import RoleCards from '../adminPanel/RoleCards'
import Settings from '../adminPanel/Settings'
import RoleNames from '../adminPanel/RoleNames';
import StaticPages from '../adminPanel/StaticPages';

import Header from './Header';
import SideBar from './SideBar';
import Footer from './Footer';

class AdminContent extends Component {
    constructor(props) {
        super(props)
    }

    renderPage = () => {
        const { tabSettings, tabNames } = this.props
        if (tabSettings.TabName == tabNames[0]) {
            return <Dashboard />
        } else if (tabSettings.TabName == tabNames[1]) {
            return <RoleNames />
        } else if (tabSettings.TabName == tabNames[2]) {
            return <RoleCards />
        } else if (tabSettings.TabName == tabNames[3]) {
            return <Games gameList={this.props.gameDataFromStore} />
        } else if (tabSettings.TabName == tabNames[4]) {
            return <StaticPages />
        } else if (tabSettings.TabName == tabNames[5]) {
            return <Settings />
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Header />
                <SideBar />
                <div className="content-wrapper" style={{ padding: '10px' }}>
                    {this.renderPage()}
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUserFromStore: state.userReducer.currentUser,
    gameDataFromStore: state.gameReducer.gameList,
    tabSettings: state.userReducer.tabSettings,
    tabNames: state.userReducer.tabNames,
})

export default connect(
    mapStateToProps,
    null
)(AdminContent)
