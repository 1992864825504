import React from 'react';

import AdminContent from '../components/AdminContent';
import Login from '../adminPanel/Login';

function AdminContainer() {
  if (JSON.parse(localStorage.getItem('adminLogin'))){
    return (
      <AdminContent />
    )
  } else {
    return (
      <Login />
    )
  }
}

export default AdminContainer;
