import React, { Component } from 'react';
import request from 'superagent';
// import { API_URL, IS_PRODUCTION, API_PORT } from '../constants'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import LoadingOverlay from 'react-loading-overlay';

import bannerSlide1 from '../assets/images/slide-1.png'
import bannerSlide2 from '../assets/images/slide-2.jpg'
import bannerSlide3 from '../assets/images/slide-3.png'
import bannerSlide4 from '../assets/images/slide-4.png'
import gameScrnshot from '../assets/images/game-screenshot.png'
import Header from './Header';
import Footer from './Footer';

export default class IntroScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gameName: 'Collaboration-Game',
            teamUserId: '',
            password: '',
            isLoading: false
        }
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`

    }

    componentWillMount() {
        const globalGameId = JSON.parse(localStorage.getItem('globalGameId'));
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        if (globalGameId && teamLoginDetails) {
            this.props.history.push({ pathname: '/game' })
        }
    }

    onLoginClick = () => {
        if (this.state.teamUserId && this.state.password) {
            this.loginWithTeam()
        } else {
            this.showAlert("Credientials can't blank !!")
        }
    }

    showAlert = (msg) => {
        confirmAlert({
            title: 'Login',
            message: msg,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        // this.props.undo()
                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async loginWithTeam() {
        this.setState({ isLoading: true })
        const r = await request.post(`${this.apiBase}/loginWithTeam`)
            .send({
                teamUserId: this.state.teamUserId,
                password: this.state.password
            })
        const response = r.body
        if (response.success) {
            this.setState({ isLoading: false })
            localStorage.setItem('globalGameId', JSON.stringify(response.ResponsePacket._id));
            localStorage.setItem('teamLoginDetails', JSON.stringify(response.ResponsePacket));
            this.props.history.push({ pathname: '/game' })
        } else {
            this.setState({ isLoading: false })
            this.showAlert(response.message)
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    render() {
        return (
            <LoadingOverlay active={this.state.isLoading} spinner>
                <div className="mainOuterDiv" >
                    <Header childProps={this.props} />
                    <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel" data-interval="0">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={bannerSlide1} className="d-block w-100" />
                                <div className="carousel-caption homeSliderContentOuter d-md-block">
                                    <div className="row" >
                                        <div className="col-md-6 text-center" >
                                            <div className="sliderContent" >
                                                <div className="loginRightBlock" >
                                                    <h2 className="m-0" >Welcome to <br />The Collaboration Game</h2>
                                                    <p className="mb-4" >online edition</p>

                                                    <div className="simpleTextOUter" >
                                                        <p>The business simulation that</p>
                                                        <p>transforms how people, teams</p>
                                                        <p>and organisations work</p>
                                                        <p>together…<a href="#" data-target="#carouselExampleCaptions" data-slide-to="1" className="text-warning" > Learn more</a></p>
                                                   

                                                    
                                                    <p className="my-3 py-3" >Already playing? Please log in:</p>
                                                    <p>Don’t have a password?</p>
                                                    <p>Email:  <a href="mailto:play@collaboration-game.com">play@collaboration-game.com</a> </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 align-self-center " >
                                            <div className="loginBoxContaienr" >
                                                <form action="../../index.html" method="post">
                                                    <div className="input-group mb-3">
                                                        <input type="teamUserId" onChange={this.onTextChange} id="teamUserId" value={this.state.teamUserId} className="form-control" placeholder="Team Name" />
                                                        {/* <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-envelope"></span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input type="password" className="form-control" onChange={this.onTextChange} id="password" value={this.state.password} placeholder="Password" />
                                                        {/* <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-lock"></span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="row">
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <button type="button" onClick={this.onLoginClick.bind(this)} className="btn btn-warning btn-block">Log in</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={bannerSlide2} className="d-block w-100" />
                                <div className="carousel-caption homeSliderContentOuter d-block">
                                    <div className="row" >
                                        <div className="col-md-6 text-center" >
                                            <div className="sliderContent" >
                                                <div className="loginRightBlock" >
                                                    <p>The Collaboration Game is a business simulation designed to improve how people work together in today’s increasingly complex organisations.</p>
                                                    <p className="my-4" > </p>
                                                    <p>In playing the game participants experience how their collective success is determined by:</p>
                                                    <p className="my-4" > </p>
                                                    <ul className="slidePOintSec" >
                                                        <li>Understanding each other’s perspectives</li>
                                                        <li>Identifying their interdependencies</li>
                                                        <li>Overcoming competing priorities</li>
                                                        <li>Communicating across functions</li>
                                                        <li>Collectively resolving issues</li>
                                                        <li>Making “joined up” decisions…<a href="#" data-target="#carouselExampleCaptions" data-slide-to="2" className="text-warning" > Learn more</a></li>
                                                    </ul>
                                                    <p className="my-4" > </p>
                                                    <p className="textStyItal mb-2" >Or to find out how your organisation can play the game,   </p>
                                                    <p> Email: <a href="mailto:enquiries@collaboration-game.com">enquiries@collaboration-game.com</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={bannerSlide3} className="d-block w-100" />
                                <div className="carousel-caption homeSliderContentOuter d-block">
                                    <div className="row" >
                                        <div className="col-md-6 text-center" >
                                            <div className="sliderContent" >
                                                <div className="loginRightBlock slide3rd" >
                                                    <p>The game is played by 6 to 8 players, each representing a typical function in your organisation. Players collaborate to improve performance whilst minimising waste. Multiple games can be played in parallel to represent different parts of an organisation or ecosystem that must collaborate together to deliver a shared goal.</p>
                                                    <p className="my-4" > </p>
                                                    <p>Learning during the game is enhanced by scenarios, insights and questions that lead to a shift in how people work together in the real world. The game and scenarios can be fully customised to reflect your branding, organisation, roles and collaboration challenges… <a href="#" data-target="#carouselExampleCaptions" data-slide-to="3" className="text-warning textStyItal" > Learn more</a></p>
                                                    <p className="my-4" > </p>
                                                    <p className="textStyItal" >Or to find out how your organisation can play the game, </p>
                                                    <p className="textStyItal" > Email: <a href="mailto:enquiries@collaboration-game.com">enquiries@collaboration-game.com</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6" >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src={bannerSlide4} className="d-block w-100" />
                                <div className="carousel-caption homeSliderContentOuter d-block">
                                    <div className="row" >
                                        <div className="col-md-6 text-center" >
                                            <div className="sliderContent bgNone" >
                                                <div className="loginRightBlock pt-5 pr-0" >
                                                    <img src={gameScrnshot} className="d-block" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 text-center" >
                                            <div className="sliderContent bgNone" >
                                                <div className="loginRightBlock slide3rd slide4rd px-3" >
                                                    <p>Whilst collaboration is a challenge for many organisations, it is often magnified for those with geographically or functionally dispersed teams who rarely meet face to face.</p>
                                                    <p className="my-4" > </p>
                                                    <p>The online edition of the game is asynchronous, reflecting the reality of different time zones and conflicting schedules. This is how collaboration happens in the real world – not in a classroom or hotel!</p>
                                                    <p>As a result, it is a flexible, scalable learning solution, transforming behaviour across an organisation.</p>
                                                    <p className="my-4" > </p>
                                                    <p>To find out how your organisation can play the game,  </p>
                                                    <p> Email: <a href="mailto:enquiries@collaboration-game.com">enquiries@collaboration-game.com</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                    <Footer childProps={this.props} />
                </div>
            </LoadingOverlay>
        )
    }
}