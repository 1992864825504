import { gameConstants } from '../constants'
import { initialDefaultState } from './initialDefaultState'

const initialState = {
    gameData: initialDefaultState.gameData,
    gameList: initialDefaultState.gameList,
    gamePlayers: initialDefaultState.Players,
    isMeJoined: false,
    gameMoves: initialDefaultState.Moves,
    gameChats: initialDefaultState.Chats,
    numberOfPlayers: initialDefaultState.numberOfPlayers,
    playerNames: initialDefaultState.playerNames,
    gameSecret: initialDefaultState.gameSecret,
    isPlayerLoggedIn: initialDefaultState.isPlayerLoggedIn,

    currentUserPlayerID: null,
    currentUserCredentials: null,
    currentGameID: null,
    currentGameName: 'Collaboration-Game',
    currentGameSetupData: {},
    currentMainTab: '',
    defaultRoleCards: initialDefaultState.DefaultRoleCards,
    defaultRoleNames: initialDefaultState.DefaultRoleNames,
    notificationsFromStore: []
}

function isMeJoined(players) {
    var result = false;
    for (let i = 0; i < players.length; i++) {
        if (players[i].isJoined === true) {
            result = true;
            break;
        }
    }
    return result
}

const gameReducer = (state = initialState, action) => {
    switch (action.type) {

        case gameConstants.PLAYER_LOGIN:
            return {
                ...state,
                isPlayerLoggedIn: action.isLogin
            }

        case gameConstants.STORE_CURRENT_GAME_DETAILS:
            return {
                ...state,
                currentGameID: action.gameData.currentGameID,
                currentGameSetupData: action.gameData.currentGameSetupData
            }

        case gameConstants.STORE_CURRENT_PLAYER_CREDENTIALS:
            return {
                ...state,
                currentUserPlayerID: action.credentials ? action.credentials.currentUserPlayerID : null,
                currentUserCredentials: action.credentials ? action.credentials.currentUserCredentials : null
            }

        case gameConstants.CREATE_NEW_GAME:
            let OldArr = state.gameList
            OldArr.push(action.gameData)
            return {
                ...state,
                gameList: OldArr
            }

        case gameConstants.UPDATE_CURRENT_PLAYER_ID:
            let Plrs = state.gamePlayers
            if (isMeJoined(Plrs)) {
                Plrs[action.playerID].isJoined = false
                return {
                    ...state,
                    currentUserPlayerID: action.playerID,
                    gamePlayers: Plrs,
                    isMeJoined: isMeJoined(Plrs)
                }
            } else {
                Plrs[action.playerID].isJoined = true
                return {
                    ...state,
                    currentUserPlayerID: action.playerID,
                    gamePlayers: Plrs,
                    isMeJoined: isMeJoined(Plrs)
                }
            }

        case gameConstants.REMOVE_CURRENT_PLAYER_ID:
            let plDT = state.gamePlayers
            if (isMeJoined(plDT)) {
                return state
            } else {
                plDT[action.playerID].isJoined = false
                return {
                    ...state,
                    currentUserPlayerID: action.playerID,
                    gamePlayers: plDT,
                    isMeJoined: isMeJoined(plDT)
                }
            }

        case gameConstants.SET_GAME_PLAYER:
            return {
                ...state,
                gamePlayers: action.playerData,
            }

        case gameConstants.SET_TAB_TO_STORE:
            return {
                ...state,
                currentMainTab: action.tabData.tabName,
            }

        case gameConstants.SET_NOTIFICATION_TO_STORE:
            return {
                ...state,
                notificationsFromStore: action.notificationData,
            }

        default:
            return state;
    }
}
export default gameReducer