export const gameConstants = {
    PLAYER_LOGIN: 'PLAYER_LOGIN',

    STORE_CURRENT_GAME_DETAILS: 'STORE_CURRENT_GAME_DETAILS',
    STORE_CURRENT_PLAYER_CREDENTIALS: 'STORE_CURRENT_PLAYER_CREDENTIALS',

    CREATE_NEW_GAME: 'CREATE_NEW_GAME',

    UPDATE_CURRENT_PLAYER_ID: 'UPDATE_CURRENT_PLAYER_ID',

    REMOVE_CURRENT_PLAYER_ID: 'REMOVE_CURRENT_PLAYER_ID',

    SET_TAB_TO_STORE: 'SET_TAB_TO_STORE',

    SET_GAME_PLAYER: 'SET_GAME_PLAYER',

    SET_NOTIFICATION_TO_STORE: 'SET_NOTIFICATION_TO_STORE'
}
