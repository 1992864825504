import { userConstants } from '../constants'
import { initialDefaultState } from './initialDefaultState'

const initialState = {
    currentUser: initialDefaultState.currentUser,
    currentUserSettings: initialDefaultState.currentUserSetings,
    tabSettings: initialDefaultState.tabSettings,
    tabNames: initialDefaultState.tabNames,
    userList: initialDefaultState.userList,
    teamList: initialDefaultState.teamList,
    gameList: initialDefaultState.gameList,




}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userConstants.USERLOGIN_REQUEST:
            return state;
        case userConstants.USERLOGIN_SUCCESS:
            return state
        case userConstants.USERLOGIN_FAILURE:
            return state;
        case userConstants.STORE_CURRENT_USER:
            return {
                ...state,
                currentUser: action.currentUser
            }
        case userConstants.STORE_CURRENT_USER_SETTINGS:
            return {
                ...state,
                currentUserSettings: action.currentUserSettings
            }

        case userConstants.UPDATE_TAB_DATA:
            return {
                ...state,
                tabSettings: action.tabSettings
            }


        case userConstants.GET_CURRENT_USER_FROM_STORE:
            return {
                ...state,
                currentUser: state.currentUser
            }
        default:
            return state;
    }
}
export default userReducer;
