import React, { Component } from 'react';
import { connect } from 'react-redux'
import { createNewGame } from '../store/actions'
import { bindActionCreators } from 'redux';
import request from 'superagent';
import LoadingOverlay from 'react-loading-overlay';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gameName: 'Collaboration-Game',
            username: 'admin',
            password: '',
            newPassword: '',
            confirmPassword: '',

            isLoading: false
        }
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    async updateAdminPassword() {

        if (this.state.newPassword != this.state.confirmPassword) {
            this.showAlert("New password and confirm password do not match!!")
            return
        }
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/updateAdmin`)
            .send({
                username: this.state.username,
                password: this.state.password,
                newPassword: this.state.newPassword
            })
        const response = r.body
        if (response.success) {
            this.setState({
                isLoading: false,
                password: '',
                newPassword: '',
                confirmPassword: ''
            }, () => {
                this.showAlert(response.message)
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                this.showAlert(response.message)
            })
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    showAlert = (msg) => {
        confirmAlert({
            title: 'Login',
            message: msg,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        // this.props.undo()
                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }


    render() {
        return (
            <LoadingOverlay active={this.state.isLoading} spinner >
                <div className="card m-10">
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body py-5">
                                    <div>
                                        {/* <div className="text-center">
                                        <img className="profile-user-img img-fluid img-circle"
                                            src="../../dist/img/user4-128x128.jpg"
                                            alt="User profile picture" />
                                    </div> */}
                                        <h1 className="profile-username text-center">Change Admin Password</h1>
                                        <br />
                                        {/* <p className="text-muted text-center">Admin</p> */}
                                    </div>
                                    <div className="row justify-content-center" >
                                        <div className="col-md-6" >
                                            {/* <div className="form-group">
                                            <label htmlFor="Username">Username</label>
                                            <input type="text" onChange={this.onTextChange} id="username" value={this.state.username} className="form-control" />
                                        </div> */}
                                            <div className="form-group">
                                                <label htmlFor="emailAddress">Current Password</label>
                                                <input type="password" onChange={this.onTextChange} id="password" value={this.state.password} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="emailAddress">New Password</label>
                                                <input type="password" onChange={this.onTextChange} id="newPassword" value={this.state.newPassword} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="emailAddress">Confirm New Password</label>
                                                <input type="password" onChange={this.onTextChange} id="confirmPassword" value={this.state.confirmPassword} className="form-control" />
                                            </div>
                                            <div className="form-group" >
                                                <button disabled={!this.state.newPassword || !this.state.confirmPassword || !this.state.password} type="button" className="btn btn-success float-right" onClick={this.updateAdminPassword.bind(this)} >Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = state => {
    return { gameList: state.gameReducer.gameList }
}

const mapDispatchToProps = dispatch => {
    // createNewGame: (data) => { dispatch(createNewGame(data)) },
    return bindActionCreators({ createNewGame: createNewGame }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)