export const DECK_HANDS = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
export const DECK_SUITS = ['S', 'T', 'R', 'I', 'D', 'E'];
export const STARTING_CARD = 'T3';
export const INVALID_CARDS = ['T2', 'E2', 'E3', 'E4'];
export const TRUMP_CARD_PREFIX = 'E';

export const SERVER_PORT = process.env.SERVER_PORT || 8000;
export const API_PORT = process.env.API_PORT || 8001;
export const CHAT_SOCKET_PORT = process.env.CHAT_SOCKET_PORT || 8003;
export const INTERNAL_API_PORT = process.env.INTERNAL_API_PORT || 8002;
export const GAME_GLOBAL_NAME = 'Collaboration-Game';
export const mongoConnectionStringProd = 'mongodb://user_collaboration-game-tk:password_collaboration-game-tk@localhost:27017/collaboration-game-tk'
// export const mongoConnectionString = 'mongodb://user_collaboration-game-tk:password_collaboration-game-tk@localhost:27017/collaboration-game-tk'

export const mongoConnectionString = 'mongodb://collab_user:collab_123@ds117899.mlab.com:17899/heroku_d1vh99p3'
export const IS_PRODUCTION = false
//API Urls
// const API_PORDUCTION_URL = `${window.location.protocol}//${window.location.hostname}`;
const API_DEVELOPMENT_URL = `http:localhost:${API_PORT}`
// const STATIC_URL = 'https://www.collaboration.tk';
const STATIC_URL = 'https://www.collaboration-game.tk';

export const API_URL = STATIC_URL