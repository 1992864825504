import React, { Component } from 'react';
import { connect } from 'react-redux'
import request from 'superagent';
// import { API_URL, IS_PRODUCTION, API_PORT } from '../constants'
import logoImg from './../assets/images/logo.png'
import loginBg from './../assets/images/login-bg.jpg'
import { updateTabNavigation } from '../store/actions'
import { Card, Nav } from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            gameName: 'Collaboration-Game',
            username: '',
            password: '',
            isLoading: false
        }
        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    onLoginClick = () => {
        if (this.state.username && this.state.password) {
            this.loginWithTeam()
        } else {
            this.showAlert("Credientials can't blank !!")
        }
    }

    showAlert = (msg) => {
        confirmAlert({
            title: 'Login',
            message: msg,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => {
                        // this.props.undo()
                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async loginWithTeam() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/adminLogin`)
            .send({
                username: this.state.username,
                password: this.state.password
            })
        const response = r.body
        if (response.success) {

            this.setState({
                isLoading: false
            }, () => {
                localStorage.setItem('adminLogin', true);
                window.location.reload();
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                this.showAlert(response.message)
            })
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    render() {
        return (
            <LoadingOverlay active={this.state.isLoading} spinner >
            <section className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card-body align-items-center d-flex justify-content-center">
                            <div className="login-box">
                                <div className="login-logo">
                                    <a href="javascript:void(0)"><b>Admin Panel</b></a>
                                </div>
                                <div className="card">
                                    <div className="card-body login-card-body">
                                        <p className="login-box-msg">Sign in to start your session</p>
                                        <form >
                                            <div className="input-group mb-3">
                                                <input type="username" onChange={this.onTextChange} id="username" value={this.state.username} className="form-control" placeholder="UserName" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-envelope"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" onChange={this.onTextChange} id="password" value={this.state.password} placeholder="Password" />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-8">

                                                </div>
                                                <div className="col-4">
                                                    <button type="button" onClick={this.onLoginClick.bind(this)} className="btn btn-primary btn-block">Sign In</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = state => ({
    tabSettings: state.userReducer.tabSettings,
    tabNames: state.userReducer.tabNames,

})

const mapDispatchToProps = dispatch => ({
    updateTabNavigation: (data) => { dispatch(updateTabNavigation(data)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
