import React from 'react';

export default class MessageList extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        const { currentUserPlayerID, gamePlayers } = this.props

        return (
            <main className="msger-chat">
                {this.props.messages.map((message, index) => {
                    if (message.playerId != null) {
                        return (
                            <div key={message._id} className={message.playerId + '' == currentUserPlayerID + '' ? "msg right-msg" : "msg left-msg"}  >
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">{gamePlayers[message.playerId].name}</div>
                                        <div className="msg-info-time">{new Date(message.created_at).toLocaleTimeString()}</div>
                                    </div>
                                    <div className="msg-text">{message.message}</div>
                                </div>
                            </div>
                        )
                    }
                })}
                <div style={{ float: "left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </main>
        )
    }
}