
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateTabNavigation } from '../store/actions'

import request from 'superagent';
// import { API_URL, API_PORT, IS_PRODUCTION } from '../constants'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';

// import { AgGridReact } from 'ag-grid-react';
import { AllModules } from 'ag-grid-react';
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

import { AgGridReact } from 'ag-grid-react';
import Popup from "reactjs-popup";


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, convertFromRaw, EditorState, ContentState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { stateFromHTML } from 'draft-js-import-html';

// import draftToMarkdown from 'draftjs-to-markdown';
import CreatableSelect from 'react-select/creatable';

import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';


class StaticPages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            selectedRoleCard: null,
            description: "",

            //ag-grid
            modules: AllModules,
            // modules: [ClientSideRowModelModule],
            columnDefs: [
                {
                    field: 'organisationName',
                    headerName: 'Organisation',
                    maxWidth: 150,
                    valueGetter: function (param) {
                        return param.data.organisationId.organisation
                    },
                },
                {
                    field: 'name',
                    headerName: 'Page Name',
                    maxWidth: 150
                },
                {
                    field: 'description',
                    headerName: 'Description',
                },
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 100,
                sortable: true,
                resizable: true,
                autoHeight: true
            },
            getRowHeight: function (params) {
                // assuming 50 characters per line, working how how many lines we need
                return 18 * (Math.floor(params.data.description.length / 50) + 1);
            },
            rowSelection: 'single',
            selectedRow: null,
            paginationPageSize: 10,
            rowData: [],
            editorState: EditorState.createEmpty(),
            organisation: '',
            organisationList: [],
            selectedOrganisation: null,
            recentlyCreatedOrgUserId: '',
            isPopupOpen: false,
            actionType: null,
            selectedPageName: null

        }
        this.onCreateStaticPage = this.onCreateStaticPage.bind(this)
        this.onAddEditStaticPage = this.onAddEditStaticPage.bind(this)
        this.onDeleteStaticPage = this.onDeleteStaticPage.bind(this)
        this.onEditStaticPage = this.onEditStaticPage.bind(this)

        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    componentDidMount() {
        this.loadStaticPages()
        this.loadOrganisations()
    }

    async loadOrganisations() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getOrganisations`)
        const response = r.body
        if (response.success) {
            const result = response.ResponsePacket
            let tempArr = []
            let selectedObj = null
            for (let i = 0; i < result.length; i++) {
                tempArr.push({
                    label: result[i].organisation,
                    value: result[i]._id,
                })

                if (this.state.recentlyCreatedOrgUserId == result[i].organisationUserId) {
                    selectedObj = { label: result[i].organisation, value: result[i]._id }
                }
            }
            this.setState({
                organisationList: tempArr,
                selectedOrganisation: selectedObj,
                organisation: selectedObj ? selectedObj.label : '',
                isLoading: false
            }, () => {
                console.log(this.state.organisationList)
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    onBtStartEditing = (key, char, pinned) => {
        this.gridApi.setFocusedCell(0, 'lastName', pinned);
        this.gridApi.startEditingCell({
            rowIndex: 0,
            colKey: 'lastName',
            rowPinned: pinned,
            keyPress: key,
            charPress: char,
        });
    };

    async loadStaticPages() {
        this.setState({
            isLoading: true
        })
        const r = await request.post(`${this.apiBase}/getStaticPages`)
        const response = r.body
        if (response.success) {
            this.setState({
                rowData: response.ResponsePacket,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    async onCreateStaticPage() {
        const { selectedOrganisation, name, selectedPageName, description } = this.state
        this.setState({
            isLoading: true
        })
        const r = await request
            .post(`${this.apiBase}/createStaticPage`)
            .send({
                organisationId: selectedOrganisation.value,
                name: selectedPageName.value,
                description: description,
                isDeleted: false,
            })
        const response = r.body
        if (response.success) {
            this.setState({
                name: "",
                selectedPageName: null,
                organisationId: "",
                description: "",
                isLoading: false,
                isPopupOpen: false
            }, () => {
                this.loadStaticPages()
                confirmAlert({
                    title: 'StaticPage',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'StaticPage',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    async onEditStaticPage() {
        const { selectedOrganisation, name, selectedPageName, description, selectedRow } = this.state
        this.setState({
            isLoading: true
        })
        const r = await request
            .post(`${this.apiBase}/editStaticPage`)
            .send({
                _id: selectedRow._id,
                organisationId: selectedOrganisation.value,
                name: selectedPageName.value,
                description: description,
                isDeleted: false,
            })
        const response = r.body
        if (response.success) {
            this.setState({
                name: "",
                selectedPageName: null,
                organisationId: "",
                description: "",
                isLoading: false,
                isPopupOpen: false,
                selectedRow: null
            }, () => {
                this.loadStaticPages()
                confirmAlert({
                    title: 'StaticPage',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'StaticPage',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }


    clearState = () => {
        this.setState({
            selectedRow: null,
            isPopupOpen: false,
            organisationId: '',
            name: '',
            description: '',
            id: '',
            isDeleted: false,
        })
    }

    async onAddEditStaticPage(type) {
        const { selectedRow, editorState } = this.state
        if (!selectedRow && type == 'edit') {
            return
        } else {

            if (type == 'edit') {

                if (selectedRow.description) {
                    var contentState = stateFromHTML(selectedRow.description);
                    this.setState({
                        editorState: EditorState.createWithContent(contentState)
                    })
                }

                this.setState({
                    selectedOrganisation: {
                        label: selectedRow.organisationId.organisation,
                        value: selectedRow.organisationId._id,
                    },
                    selectedPageName: {
                        value: selectedRow.name,
                        label: selectedRow.name
                    },
                    isPopupOpen: true,
                    organisationId: selectedRow._id,
                    description: selectedRow.description,
                    name: selectedRow.name,
                    isDeleted: false,
                    actionType: 'Update Static Page'
                })
            } else {
                this.setState({
                    isPopupOpen: true,
                    organisationId: '',
                    name: '',
                    description: '',
                    editorState: EditorState.createEmpty(),
                    id: '',
                    selectedOrganisation: null,
                    selectedPageName: null,
                    isDeleted: false,
                    actionType: 'Add Static Page'
                })
            }
        }
    }

    async onDeleteStaticPage() {
        const { selectedRow } = this.state
        confirmAlert({
            title: 'Delete StaticPage',
            message: "Are you sure you wants to delete the record : " + selectedRow.name + "?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        this.deleteStaticPage()
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {

                    }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    async deleteStaticPage() {
        const { selectedRow } = this.state
        this.setState({ isLoading: true })
        const r = await request
            .post(`${this.apiBase}/deleteStaticPage`)
            .send({
                _id: selectedRow._id
            })
        const response = r.body
        if (response.success) {
            this.setState({
                name: "",
                organisationId: "",
                description: "",
                isLoading: false,
                selectedRow: null
            }, () => {
                this.loadStaticPages()
                confirmAlert({
                    title: 'StaticPage',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            }, () => {
                confirmAlert({
                    title: 'StaticPage',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        }
    }

    onTextChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleImageUploadChange(event) {
        this.setState({
            gameBoard: URL.createObjectURL(event.target.files[0])
        })
    }

    onClickTab = (tabName) => {
        this.props.updateTabNavigation({ TabName: tabName })
    }

    handleChange = selectedRoleCard => {
        this.setState(
            { selectedRoleCard },
            () => console.log(`Option selected:`, this.state.selectedRoleCard)
        );
    }

    handlePageName = selectedPageName => {
        this.setState(
            { selectedPageName },
            () => console.log(`Option selected:`, this.state.selectedPageName)
        );
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            // description: editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent())),
            description: editorState && convertToHTML(editorState.getCurrentContent())
        })
    }

    onOrganisationChange = (newValue, actionMeta) => {

        if (actionMeta.action == "create-option") {
            this.setState({
                organisation: newValue.label
            }, () => {
                this.onCreateOrganisation()
            })
        } else if (actionMeta.action == "select-option") {
            this.setState({
                selectedOrganisation: newValue,
                organisation: newValue.label,
            })
        }
    }

    async onCreateOrganisation() {
        this.setState({
            isLoading: true,
            recentlyCreatedOrgUserId: ''
        })
        const r = await request
            .post(`${this.apiBase}/createOrganisation`)
            .send({
                organisationUserId: this.state.organisation.split(" ")[0].toLocaleLowerCase() + Math.floor(1000 + Math.random() * 9000),
                organisation: this.state.organisation,
                gameBoard: "",
                address: "",
                country: "",
                postCode: "",
                isActive: true,
                isDeleted: false
            })
        const response = r.body
        if (response.success) {
            this.setState({
                organisationUserId: "",
                recentlyCreatedOrgUserId: response.ResponsePacket.organisationUserId,
                organisation: "",
                gameBoard: "",
                isLoading: false
            }, () => {
                this.loadOrganisations()
                confirmAlert({
                    title: 'Organisation Created',
                    message: response.message,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => {

                            }
                        }
                    ],
                    closeOnClickOutside: false,
                    closeOnEscape: false
                })
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    onSelectionChanged = () => {
        var selectedRows = this.gridApi.getSelectedRows()
        this.setState({
            selectedRow: selectedRows && selectedRows.length > 0 ? selectedRows[0] : null
        })
    }

    render() {
        const { isLoading } = this.state
        return (
            <LoadingOverlay
                active={isLoading}
                spinner
            >
                <div className="card m-10">
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <div style={{ padding: '0 15px 15px', display: 'inline-block', width: '100%' }}>
                                        <button
                                            disabled={!this.state.selectedRow}
                                            type="button"
                                            className="btn btn-danger float-right btn-sm"
                                            onClick={this.onDeleteStaticPage}
                                        >{'Delete Record'}</button>
                                        <button
                                            disabled={!this.state.selectedRow}
                                            type="button"
                                            className="btn btn-success float-right btn-sm mx-2"
                                            onClick={this.onAddEditStaticPage.bind(this, 'edit')}
                                        >{'Update Record'}</button>
                                        <button
                                            type="button"
                                            className="btn btn-success float-right btn-sm"
                                            onClick={this.onAddEditStaticPage.bind(this, 'add')}
                                        >{'Add New'}</button>
                                    </div>
                                    <div className="card-body table-responsive p-0">
                                        <div style={{ width: '100%', height: '550px' }}>
                                            <div
                                                id="StaticPageGrid"
                                                style={{
                                                    height: '550px',
                                                    width: '100%',
                                                }}
                                                className="ag-theme-alpine"
                                            >
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={this.state.columnDefs}
                                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    defaultColDef={this.state.defaultColDef}
                                                    // suppressRowClickSelection={true}
                                                    // groupSelectsChildren={true}
                                                    enableCellTextSelection={true}

                                                    rowSelection={this.state.rowSelection}
                                                    // rowGroupPanelShow={this.state.rowGroupPanelShow}
                                                    // pivotPanelShow={this.state.pivotPanelShow}
                                                    // enableRangeSelection={true}
                                                    onGridReady={this.onGridReady}
                                                    rowData={this.state.rowData}
                                                    pagination={true}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    // cacheBlockSize={this.state.cacheBlockSize}
                                                    // cellClicked={(event) => this.onCellClicked(event)}
                                                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                    rowDeselection={true}
                                                // getRowHeight={this.state.getRowHeight}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Popup open={this.state.isPopupOpen} closeOnDocumentClick={false} repositionOnResize={true}>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group align-center">
                                    <h3 className="text-center m-2">{this.state.actionType} </h3>
                                </div>
                                <div style={{ marginTop: '40px' }}>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="organisation">Organisation</label>
                                            <CreatableSelect
                                                isClearable
                                                isDisabled={this.state.actionType == 'Update Static Page'}
                                                value={this.state.selectedOrganisation}
                                                onChange={this.onOrganisationChange}
                                                onInputChange={this.handleInputChange}
                                                options={this.state.organisationList}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Page Name <span style={{ fontSize: 10 }}> (for example : Game Setup/Game Rule)</span></label>
                                            <Select
                                                id={"moveNumber"}
                                                isDisabled={this.state.actionType == 'Update Static Page'}
                                                disabled
                                                value={this.state.selectedPageName}
                                                onChange={this.handlePageName}
                                                options={[
                                                    { value: 'Game Setup', label: 'Game Setup' },
                                                    { value: 'Game Rule', label: 'Game Rule' },
                                                ]}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Page Contents</label>
                                            {/* <textarea onChange={this.onTextChange} id="description" value={this.state.description} className="form-control" /> */}
                                            <Editor
                                                editorState={this.state.editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ padding: '0 15px 15px', display: 'inline-block', width: '100%' }}>
                                    <button
                                        type="button"
                                        className="btn btn-warning float-right btn-sm mx-1"
                                        onClick={() => {
                                            this.setState({ isPopupOpen: false }, () => {
                                                this.clearState()
                                            })
                                        }}
                                    >{'Cancel'}</button>
                                    <button
                                        // disabled={!this.state.selectedOrganisation || !this.state.name}
                                        type="button"
                                        className="btn btn-success float-right btn-sm mx-1"
                                        onClick={this.state.actionType == 'Update Static Page' ? this.onEditStaticPage : this.onCreateStaticPage}
                                    >{'Submit'}</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
            </LoadingOverlay>
        )
    }
}

const mapStateToProps = state => ({
    tabSettings: state.userReducer.tabSettings

})

const mapDispatchToProps = dispatch => ({
    updateTabNavigation: (data) => { dispatch(updateTabNavigation(data)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StaticPages)
