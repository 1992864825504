import React, { Component } from 'react';
import { connect } from 'react-redux'
import logoImg from '../assets/images/logo.png'
import $ from 'jquery';
import { storeCurrentPlayerCredentials, setTabToStore, setNotificationToStore } from '../store/actions'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import request from 'superagent';

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedIn: true,
            notificationCount: 0,
            globalGameId: null
        }

        process.env.REACT_APP_STAGE == 'production' ? this.apiBase = process.env.REACT_APP_API_URL + '/api' : this.apiBase = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    }

    componentDidMount = () => {
        this.props.setTabToStore({ tabName: window.location.pathname ? window.location.pathname.split("/")[1] : "" })

        $(".canvas-open").on('click', function () {
            $(".offcanvas-menu-wrapper").addClass("show-offcanvas-menu-wrapper");
            $(".offcanvas-menu-overlay").addClass("active");
        })

        $(".canvas-close, .offcanvas-menu-overlay").on('click', function () {
            $(".offcanvas-menu-wrapper").removeClass("show-offcanvas-menu-wrapper");
            $(".offcanvas-menu-overlay").removeClass("active");
        })
    }

    componentWillMount() {
        // const globalGameId = JSON.parse(localStorage.getItem('globalGameId'));
        // const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        // if (globalGameId && teamLoginDetails != null) {
        //     this.setState({ globalGameId: globalGameId }, () => {
        //         this.loadNotificationData()
        //     })
        // }
    }

    goToPage = (pagename) => {
        // this.props.setTabToStore({ tabName: pagename })
        if (this.props.childProps.history) {
            this.props.childProps.history.push("/" + pagename)
        } else {
            this.props.childProps.childProps.history.push("/" + pagename)
        }
    }

    goToLogout = () => {
        confirmAlert({
            title: 'Logout',
            message: "Are you sure you want to logout?",
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        localStorage.setItem('currentPlayerStorage', JSON.stringify({ currentUserPlayerID: null, currentUserCredentials: null }))
                        localStorage.setItem('globalGameId', null)
                        localStorage.setItem('teamLoginDetails', null)
                        this.props.storeCurrentPlayerCredentials({ currentUserPlayerID: null, currentUserCredentials: null })
                        this.goToPage("")
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => { }
                }
            ],
            closeOnCllickOutside: false,
            closeOnEscape: false
        })
    }

    onClickMenu = () => {
        $(".canvas-open").on('click', function () {
            $(".offcanvas-menu-wrapper").addClass("show-offcanvas-menu-wrapper");
            $(".offcanvas-menu-overlay").addClass("active");
        })

        $(".canvas-close, .offcanvas-menu-overlay").on('click', function () {
            $(".offcanvas-menu-wrapper").removeClass("show-offcanvas-menu-wrapper");
            $(".offcanvas-menu-overlay").removeClass("active");
        })
    }

    async onMarkAsRead(_id) {
        const r = await request
            .post(`${this.apiBase}/markAsRead`)
            .send({
                _id: _id
            })
        const response = r.body
        if (response.success) {
            this.loadNotificationData()
        }
    }

    async loadNotificationData() {
        const r = await request
            .post(`${this.apiBase}/getNotifications`)
            .send({
                gameId: this.state.globalGameId
            })
        const response = r.body
        if (response.success) {
            this.setState({
                notificationCount: response.countDocuments
            }, () => {
                this.props.setNotificationToStore(response.ResponsePacket)
            })
        }
    }

    render() {
        const { currentUserCredentials, currentUserPlayerID, currentMainTabFromStore, notificationsFromStore } = this.props

        const globalGameId = JSON.parse(localStorage.getItem('globalGameId'));
        const teamLoginDetails = JSON.parse(localStorage.getItem('teamLoginDetails'))
        return (
            <div>
                <div className="offcanvas-menu-overlay"></div>
                <div className="offcanvas-menu-wrapper">
                    <div className="canvas-close">
                        <i className="fa fa-close"></i>
                    </div>
                    <div className="search-btn search-switch">
                        <div className="logo">
                            <a href="#!" data-target="#carouselExampleCaptions" data-slide-to="0" style={{ cursor: 'pointer' }}
                            // onClick={this.goToPage.bind(this, '')} 
                            > <img src={logoImg} alt="" /> </a>
                        </div>
                    </div>
                    <div className="header__top--canvas">
                        {/* <div className="ht-info">
                        <ul>
                            <li>20:00 - May 19, 2019</li>
                            <li><a href="#!">Sign in</a></li>
                            <li><a href="#!">Contact</a></li>
                        </ul>
                    </div>
                    <div className="ht-links">
                        <a href="#!"><i className="fa fa-facebook"></i></a>
                        <a href="#!"><i className="fa fa-vimeo"></i></a>
                        <a href="#!"><i className="fa fa-twitter"></i></a>
                        <a href="#!"><i className="fa fa-google-plus"></i></a>
                        <a href="#!"><i className="fa fa-instagram"></i></a>
                    </div> */}
                    </div>
                    <div id="mobile-menu-wrap"><div className="slicknav_menu">
                        <ul className="slicknav_nav slicknav_hidden">

                            {/* <li style={{ cursor: 'pointer' }} style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'AboutUs' ? 'active' : ''} onClick={this.goToPage.bind(this, 'AboutUs')}><a >About Game</a></li> */}
                            {teamLoginDetails && globalGameId && <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'GameSetup' ? 'active' : ''} onClick={this.goToPage.bind(this, 'GameSetup')}><a href="#!">Game Setup</a></li>}
                            {teamLoginDetails && globalGameId && <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'GameRules' ? 'active' : ''} onClick={this.goToPage.bind(this, 'GameRules')}><a href="#!">Game Rules</a></li>}
                            {teamLoginDetails && globalGameId && <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'RoleDescription' ? 'active' : ''} onClick={this.goToPage.bind(this, 'RoleDescription')}><a >Role Description</a></li>}
                            <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'game' ? 'active' : ''} onClick={this.goToPage.bind(this, 'game')}><a >Game Play</a></li>
                            <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'ContactUs' ? 'active' : ''} onClick={this.goToPage.bind(this, 'ContactUs')}><a  >Support</a></li>
                            {teamLoginDetails && globalGameId ?
                                <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'Login' ? 'active' : ''} onClick={this.goToLogout.bind(this, '')}><a >Logout</a></li>
                                :
                                <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'Login' ? 'active' : ''} onClick={this.goToPage.bind(this, '')}><a >Login</a></li>}
                            {/* {
                                teamLoginDetails && globalGameId &&
                                <li class="nav-item dropdown">
                                    <a class="nav-link" data-toggle="dropdown" href="#!">
                                        <i class="far fa-bell" style={{ fontSize: 16 }}></i>
                                        <span class="badge badge-danger navbar-badge">{this.state.notificationCount}</span>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right notificationOuter">
                                        <span class="dropdown-item dropdown-header">{this.state.notificationCount} Notifications</span>
                                        <div class="dropdown-divider"></div>
                                        {
                                            notificationsFromStore.map((value, indx) => {
                                                return (
                                                    <>
                                                        <a href="#!" class="dropdown-item" key={indx}>
                                                            {'Player : ' + parseInt(value.playerId + 1) + ' joined recently.'}
                                                            <span class="float-right text-muted text-sm">{new Date(value.created_at).toLocaleTimeString()}</span>
                                                        </a>
                                                        {
                                                            !value.isRead && <button type="buttton" className="btn btn-success btn-sm text-sm" onClick={this.onMarkAsRead.bind(this, value._id)}>Mark as Read</button>
                                                        }
                                                        <div class="dropdown-divider"></div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </li>
                            } */}
                        </ul>
                    </div>
                    </div>
                    <div id="mobile-menu-wrap"></div>
                </div>
                <header className="header-section">
                    <div className="header__nav">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="logo">
                                        <a style={{ cursor: 'pointer' }}
                                            data-target="#carouselExampleCaptions" data-slide-to="0"
                                        // onClick={this.goToPage.bind(this, '')} 
                                        > <img src={logoImg} alt="" /> </a>
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="nav-menu">
                                        <ul className="main-menu">

                                            {/* <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'AboutUs' ? 'active' : ''} onClick={this.goToPage.bind(this, 'AboutUs')}><a >About Game</a></li> */}
                                            {teamLoginDetails && globalGameId && <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'GameSetup' ? 'active' : ''} onClick={this.goToPage.bind(this, 'GameSetup')}><a >Game Setup</a></li>}
                                            {teamLoginDetails && globalGameId && <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'GameRules' ? 'active' : ''} onClick={this.goToPage.bind(this, 'GameRules')}><a >Game Rules</a></li>}
                                            {teamLoginDetails && globalGameId && <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'RoleDescription' ? 'active' : ''} onClick={this.goToPage.bind(this, 'RoleDescription')}><a >Role Description</a></li>}
                                            <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'game' ? 'active' : ''} onClick={this.goToPage.bind(this, '')}><a >Game Play</a></li>
                                            <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'ContactUs' ? 'active' : ''} onClick={this.goToPage.bind(this, 'ContactUs')}><a  >Support</a></li>
                                            {
                                                teamLoginDetails && globalGameId ?
                                                    <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'Login' ? 'active' : ''} onClick={this.goToLogout.bind(this, '')}><a >Logout</a></li>
                                                    :
                                                    <li style={{ cursor: 'pointer' }} className={currentMainTabFromStore == 'Login' ? 'active' : ''} onClick={this.goToPage.bind(this, '')}><a >Login</a></li>
                                            }
                                            {/* {
                                                teamLoginDetails && globalGameId &&
                                                <li class="nav-item dropdown">
                                                    <a class="nav-link" data-toggle="dropdown" href="#!">
                                                        <i class="far fa-bell" style={{ fontSize: 16 }}></i>
                                                        <span class="badge badge-danger navbar-badge">{this.state.notificationCount}</span>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right  notificationOuter">
                                                        <span class="dropdown-item dropdown-header">{this.state.notificationCount} Notifications</span>
                                                        <div class="dropdown-divider"></div>
                                                        {
                                                            notificationsFromStore.map((value, indx) => {
                                                                return (
                                                                    <>
                                                                        <a href="#!" class="dropdown-item" key={indx}>
                                                                            {'Player : ' + parseInt(value.playerId + 1) + ' joined recently.'}
                                                                            <span class="float-right text-muted text-sm">{new Date(value.created_at).toLocaleTimeString()}</span>
                                                                        </a>
                                                                        {
                                                                            !value.isRead && <button type="buttton" className="btn btn-success btn-sm" onClick={this.onMarkAsRead.bind(this, value._id)}>Mark as Read</button>
                                                                        }
                                                                        <div class="dropdown-divider"></div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </li>
                                            } */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div onClick={this.onClickMenu.bind(this)} className="canvas-open">
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    currentUserFromStore: state.userReducer.currentUser,
    tabSettings: state.userReducer.tabSettings,
    currentUserCredentials: state.gameReducer.currentUserCredentials,
    currentUserPlayerID: state.gameReducer.currentUserPlayerID,
    currentMainTabFromStore: state.gameReducer.currentMainTab,
    notificationsFromStore: state.gameReducer.notificationsFromStore
})

const mapDispatchToProps = dispatch => ({
    storeCurrentPlayerCredentials: (credentials) => { dispatch(storeCurrentPlayerCredentials(credentials)) },
    setTabToStore: (data) => { dispatch(setTabToStore(data)) },
    setNotificationToStore: (playerData) => { dispatch(setNotificationToStore(playerData)) },

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)
