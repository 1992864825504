import * as SP from '../utils/StaticProperties'

// Chips

//  0. Investment : 1000 
//  1. Budget : 100 
//  2. Innovation : 100 
//  3. Production And Service : 100 
//  4. Profit : 100 
//  5. Demand : 100 
//  6. Reputation : 100 
//  7. Quality Of Delivery(Performance) : 100 
//  8. Employment Engagement : 100 
//  9. Retention : 100 
//  10. Capability : 100 
//  11. Talent : 1000 
//  12. Waste : 0 
//  13. Improvement : 5000 

export function move(G, ctx, amount, chipFromIndex, chipToIndex) {
    let arrChips = G.cells
    let extratokens = 0
    let requiredtokens = 0

    let chipsValues = {
        Investment: arrChips[0],
        Budget: arrChips[1],
        Innovation: arrChips[2],
        ProductionAndService: arrChips[3],
        Profit: arrChips[4],
        Demand: arrChips[5],
        Reputation: arrChips[6],
        QualityOfDelivery: arrChips[7],
        EmploymentEngagement: arrChips[8],
        Retention: arrChips[9],
        Capability: arrChips[10],
        Talent: arrChips[11],
        Waste: arrChips[12],
        Improvement: arrChips[13],
    }

    switch (parseInt(ctx.currentPlayer)) {

        // Investor : Investor will move twice
        case 0:
            if (ctx.numMoves == 0) {
                // if ((amount > chipsValues.Profit) || (amount == 0)) {
                if (amount > chipsValues.Profit) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                } else {
                    arrChips[0] -= amount
                    arrChips[1] += amount
                }

            } else if (ctx.numMoves == 1) {
                if (amount > chipsValues.Profit) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                } else {
                    arrChips[0] += amount
                    arrChips[4] -= amount
                }
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        // Sales or (sales & marketing)
        case 1:
            if (ctx.numMoves == 0) {
                /*
                 * @desc this is the first move, the rule says that amount moves from innovation to products and sevices
                 * @TODO refactor refactor refactor refactor
                 */
                if (amount > chipsValues.Innovation) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                } else {
                    arrChips[2] -= amount
                    arrChips[3] += amount
                }

            } else if (ctx.numMoves == 1) {
                /*
                 * @desc if it is a 2nd move, we will move amount from budget to innovation
                 * and also switch the current player from sales & marketing to prospects
                 * 
                 */
                if (amount > chipsValues.Budget) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                } else {
                    arrChips[2] += amount
                    arrChips[1] -= amount
                }
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        // Marketing or Prospects
        case 2:
            if (ctx.numMoves == 0) {
                /*
                  * @desc this is the first move, the rule says that Increase or decrease the value of tokens in demand so that it equals the value in products and
                   services. Tokens should be added from improvement or removed to waste as necessary.
                  * @TODO refactor refactor refactor refactor
                  */
                if (chipsValues.Demand > chipsValues.ProductionAndService) {
                    extratokens = (chipsValues.Demand - chipsValues.ProductionAndService)

                    if (amount != extratokens) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    arrChips[12] += extratokens
                    arrChips[5] -= extratokens

                } else {
                    requiredtokens = (chipsValues.ProductionAndService - chipsValues.Demand)

                    if (amount != requiredtokens) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    arrChips[13] -= requiredtokens
                    arrChips[5] += requiredtokens
                }

            } else if (ctx.numMoves == 1) {
                /*
                    * @desc if it is a 2nd move, Increase or decrease the value of tokens in demand so that any difference between demand
                     and reputation is halved.
                    * and also switch the current player from prospects to human resources
                    * 
                    */
                let diff = chipsValues.Demand - chipsValues.Reputation
                let diffPositive = Math.abs(diff)
                let requiredAmount = (diffPositive / 2)

                requiredAmount = Math.ceil(requiredAmount)

                if (amount != requiredAmount) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                } else {
                    if (chipsValues.Demand < chipsValues.Reputation) {
                        arrChips[5] += amount
                        arrChips[13] -= amount
                    } else {
                        arrChips[5] -= amount
                        arrChips[12] += amount
                    }
                }
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }


        // Human Resources
        case 3:
            if (ctx.numMoves == 0) {
                /*
                 * @desc this is the first move, the rule says that amount moves from quality of delivery to employee engagement
                 * @TODO refactor refactor refactor refactor
                 */

                // if it is small then move them all
                if (chipsValues.QualityOfDelivery <= chipsValues.Reputation) {
                    if (amount != chipsValues.QualityOfDelivery) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    arrChips[8] += arrChips[7]
                    arrChips[7] = 0
                }

                // if it is grater then do the calculation
                if (chipsValues.QualityOfDelivery > chipsValues.Reputation) {

                    if (amount != chipsValues.Reputation) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    // Move the equivalent value to reputation from quality of delivery to employee engagement
                    arrChips[8] += arrChips[6]

                    //remove any excess tokens left on quality of delivery to waste
                    arrChips[7] -= arrChips[6]
                    arrChips[12] += arrChips[7]
                    arrChips[7] = 0
                }

            } else if (ctx.numMoves == 1) {
                /*
                 * @desc if it is a 2nd move, we will move amount from employmentEngagement to retention
                 * 
                 */
                if (amount > chipsValues.EmploymentEngagement) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }

                arrChips[9] += amount
                arrChips[8] -= amount

            } else if (ctx.numMoves == 2) {
                /*
                 * @desc if it is a 3rd move, Add 1/10th of reputation amount from improvement to employee engagement
                 * and also switch the current player from human resource to recruiters
                 */

                // let amounttomove = (chipsValues.Reputation / 10)
                let amounttomove = ((chipsValues.Reputation / 100) * 20)
                amounttomove = Math.ceil(amounttomove)

                if (amount != amounttomove) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }

                /*
                 * @TODO - set according to players choices
                 */
                arrChips[chipToIndex] += amounttomove
                arrChips[13] -= amounttomove
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        // Head hunters or Recruiters
        case 4:
            if (ctx.numMoves == 0) {
                /* * @desc this is the first move, the rule says that Increase or decrease the value of tokens in demand so that it equals the value in products and
                     services. Tokens should be added from improvement or removed to waste as necessary.
                    * @TODO refactor refactor refactor refactor
                    */

                if (amount <= chipsValues.EmploymentEngagement) {
                    arrChips[11] -= amount
                    arrChips[9] += amount
                } else {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }

            } else if (ctx.numMoves == 1) {

                /*
                * @desc if it is a 2nd move, Increase or decrease the value of tokens in demand so that any difference between demand
                and reputation is halved.
                * and also switch the current player from prospects to human resources
                * 
                */
                if (amount <= chipsValues.EmploymentEngagement) {
                    arrChips[11] += amount
                    arrChips[8] -= amount
                } else {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }
            }

            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        // Operations
        case 5:
            if (ctx.numMoves == 0) {
                /*
                 * @desc this is the first move, the rule says that Increase or decrease the value of tokens in demand so that it equals the value in products and
                  services. Tokens should be added from improvement or removed to waste as necessary.
                 * @TODO refactor refactor refactor refactor
                 */

                if (amount <= chipsValues.Capability) {
                    arrChips[10] -= amount
                    arrChips[7] += amount
                } else {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }

            } else if (ctx.numMoves == 1) {
                /*
                 * @desc if it is a 2nd move, Increase or decrease the value of tokens in demand so that any difference between demand
                  and reputation is halved.
                 * and also switch the current player from prospects to human resources
                 */
                if (amount <= chipsValues.Retention) {
                    arrChips[10] += amount
                    arrChips[9] -= amount
                } else {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        // Customers
        case 6:
            if (ctx.numMoves == 0) {
                /*
                 * @desc this is the first move, the rule says that Increase or decrease the value of tokens in demand so that it equals the value in products and
                  services. Tokens should be added from improvement or removed to waste as necessary.
                 * @TODO refactor refactor refactor refactor
                 */
                if (chipsValues.Reputation > chipsValues.QualityOfDelivery) {
                    let diff = (chipsValues.Reputation - chipsValues.QualityOfDelivery)

                    if (amount != diff) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    arrChips[6] -= diff
                    arrChips[12] += diff


                } else if (chipsValues.QualityOfDelivery > chipsValues.Reputation) {
                    let diff = (chipsValues.QualityOfDelivery - chipsValues.Reputation)

                    if (amount != diff) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }
                    arrChips[13] -= diff
                    arrChips[6] += diff

                } else {

                    if (amount != 0) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }
                }

            } else if (ctx.numMoves == 1) {

                /*
                 * @desc if it is a 2nd move, Increase or decrease the value of tokens in demand so that any difference between demand
                  and reputation is halved.
                 * and also switch the current player from prospects to human resources
                 */

                let diff = chipsValues.Reputation - chipsValues.Demand
                let diffPositive = Math.abs(diff)
                let requiredAmount = Math.ceil(diffPositive / 2)

                if (amount != requiredAmount) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                } else {
                    if (chipsValues.Reputation < chipsValues.Demand) {
                        arrChips[6] += amount
                        arrChips[13] -= amount
                    } else {
                        arrChips[6] -= amount
                        arrChips[12] += amount
                    }
                }
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        // Finance
        case 7:
            if (ctx.numMoves == 0) {
                /*
                 * @desc this is the first move, the rule says that amount moves from quality of delivery to employee engagement
                 * @TODO refactor refactor refactor refactor
                 */

                // if it is small then move them all
                if (chipsValues.ProductionAndService <= chipsValues.Demand) {

                    if (amount != chipsValues.ProductionAndService) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    arrChips[4] += arrChips[3]
                    arrChips[3] = 0
                }

                // if it is grater then do the calculation
                if (chipsValues.ProductionAndService > chipsValues.Demand) {
                    if (amount != chipsValues.Demand) {
                        return {
                            isMoved: false,
                            result: arrChips,
                            message: "Ooops, please refer to your role card and try again."
                        }
                    }

                    // Move the equivalent value to reputation from quality of delivery to employee engagement
                    arrChips[4] += arrChips[5]

                    //remove any excess tokens left on quality of delivery to waste
                    arrChips[3] -= arrChips[5]
                    arrChips[12] += arrChips[3]

                    arrChips[3] = 0
                }

            } else if (ctx.numMoves == 1) {
                /*
                 * @desc if it is a 2nd move, we will move amount from employmentEngagement to retention
                 */

                if (amount > chipsValues.Profit) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }

                arrChips[1] += amount
                arrChips[4] -= amount

            } else if (ctx.numMoves == 2) {
                /*
                 * @desc if it is a 3rd move, Add 1/10th of reputation amount from improvement to employee engagement
                 * and also switch the current player from human resource to recruiters
                 */

                // let amounttomove = (chipsValues.Demand / 10)
                let amounttomove = ((chipsValues.Demand / 100) * 20)
                amounttomove = Math.ceil(amounttomove)

                if (amount != amounttomove) {
                    return {
                        isMoved: false,
                        result: arrChips,
                        message: "Ooops, please refer to your role card and try again."
                    }
                }

                /*
                 * @TODO - set value according player choices
                 */
                arrChips[chipToIndex] += amounttomove
                arrChips[13] -= amounttomove

                // $this -> _updateScoreCard()
            }
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }

        default:
            return {
                isMoved: true,
                result: arrChips,
                message: ''
            }
    }
} 