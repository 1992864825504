import React, { Component } from 'react';
import { connect } from 'react-redux'
import { storeCurrentUser, updateTabNavigation } from '../store/actions'

class SideBar extends Component {

    onClickTab = (tabName) => {
        this.props.updateTabNavigation({ TabName: tabName })
    }

    render() {
        const { tabSettings, currentUserFromStore, tabNames } = this.props
        return (
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a className="brand-link">
                    <img src="dist/img/AdminLTELogo.png" alt="Logo" className="brand-image img-circle elevation-3" />
                    <span className="brand-text font-weight-light text-light">GAME BOARD</span>
                </a>
                <div className="sidebar">
                    {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div className="info">
                            <a href="#" className="d-block">{currentUserFromStore.Name}</a>
                        </div>
                    </div> */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item has-treeview menu-open" onClick={this.onClickTab.bind(this, tabNames[0])}>
                                <a className={"nav-link " + (tabSettings.TabName == tabNames[0] ? 'active' : '')} >
                                    <i className="nav-icon fas fa-home"></i>
                                    <p>{tabNames[0]}</p>
                                </a>
                            </li>
                            <li className="nav-item has-treeview menu-open" onClick={this.onClickTab.bind(this, tabNames[1])}>
                                <a className={"nav-link " + (tabSettings.TabName == tabNames[1] ? 'active' : '')}>
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>{tabNames[1]}</p>
                                </a>
                            </li>
                            <li className="nav-item has-treeview menu-open" onClick={this.onClickTab.bind(this, tabNames[2])}>
                                <a className={"nav-link " + (tabSettings.TabName == tabNames[2] ? 'active' : '')}>
                                    <i className="nav-icon fas fa-user"></i>
                                    <p>{tabNames[2]}</p>
                                </a>
                            </li>
                            <li className="nav-item has-treeview menu-open" onClick={this.onClickTab.bind(this, tabNames[3])}>
                                <a className={"nav-link " + (tabSettings.TabName == tabNames[3] ? 'active' : '')}>
                                    <i className="nav-icon fas fa-gamepad"></i>
                                    <p>{tabNames[3]}</p>
                                </a>
                            </li>
                            <li className="nav-item has-treeview menu-open" onClick={this.onClickTab.bind(this, tabNames[4])}>
                                <a className={"nav-link " + (tabSettings.TabName == tabNames[4] ? 'active' : '')}>
                                    <i className="nav-icon fas fa-paste"></i>
                                    <p>{tabNames[4]}</p>
                                </a>
                            </li>
                            <li className="nav-item has-treeview menu-open" onClick={this.onClickTab.bind(this, tabNames[5])}>
                                <a className={"nav-link " + (tabSettings.TabName == tabNames[5] ? 'active' : '')}>
                                    <i className="nav-icon fas fa-cog"></i>
                                    <p>{tabNames[5]}</p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        )
    }
}

const mapStateToProps = state => ({
    currentUserFromStore: state.userReducer.currentUser,
    gameDataFromStore: state.gameReducer.gameData,
    tabSettings: state.userReducer.tabSettings,
    tabNames: state.userReducer.tabNames,
})

const mapDispatchToProps = dispatch => ({
    updateTabNavigation: (data) => { dispatch(updateTabNavigation(data)) },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideBar)
