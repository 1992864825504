import { userConstants } from '../constants'
import { userService } from '../services'

// export function UserLogin(tdata) {
//     return dispatch => {
//         // dispatch(loaderActions.showLoader());
//         dispatch(request())
//         userService.UserLogin(tdata)
//             .then(
//                 data => {                 
//                     dispatch(success(data))
//                 },
//                 error => {
//                     dispatch(failure(error))
//                 }
//             )
//     }
//     function request() { return { type: userConstants.USERLOGIN_REQUEST } }
//     function success(data) { return { type: userConstants.USERLOGIN_SUCCESS, data } }
//     function failure(error) { return { type: userConstants.USERLOGIN_FAILURE, error } }
// }

export const currentUser = (user) => {
    return {
        type: userConstants.CURRENT_USER,
        user: user,
    }
}

export const storeCurrentUser = (currentUser) => {
    return {
        type: userConstants.STORE_CURRENT_USER,
        currentUser: currentUser,
    }
}

export const storeCurrentUserSettings = (currentUserSettings) => {
    return {
        type: userConstants.STORE_CURRENT_USER_SETTINGS,
        currentUserSettings: currentUserSettings,
    }
}




export const getCurrentUserFromStore = (currentUser) => {
    return {
        type: userConstants.GET_CURRENT_USER_FROM_STORE,
        currentUser: currentUser,
    }
}

export const updateTabNavigation = (tabSettings) => {
    return {
        type: userConstants.UPDATE_TAB_DATA,
        tabSettings: tabSettings,
    }
}

