import React from 'react';
import { Provider } from "react-redux";

//Styling
import '../App.css';
import '../assets/css/bootstrap.min.css'
import '../assets/css/style.css'

import Login from '../adminPanel/Login'
import AdminContainer from './AdminContainer'
import GameContainer from './GameContainer'
// import WebsiteContainer from './WebsiteContainer'

import configureStore from '../../src/store/configureStore'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ContactUs from '../websitePanel/contact-us'
import AboutUs from '../websitePanel/AboutUs';
import GameSetup from '../websitePanel/GameSetup';
import GameRules from '../websitePanel/GameRules';
import RoleDescription from '../websitePanel/RoleDescription';
import Support from '../websitePanel/Support';
import IntroScreen from '../websitePanel/IntroScreen';
// import { requestFirebaseNotificationPermission } from '../firebaseInitClient'

const store = configureStore()


// requestFirebaseNotificationPermission()
//     .then((firebaseToken) => {
//         // eslint-disable-next-line no-console
//         console.log(firebaseToken);
//     })
//     .catch((err) => {
//         return err;
//     });

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Route exact pattern='/game' path="/game" component={GameContainer} />
                <Route exact pattern='/login' path="/login" component={Login} />
                <Route exact pattern='/admin' path="/admin" component={AdminContainer} />

                <Route exact pattern='/' path="/" component={IntroScreen} />
                <Route exact pattern='/ContactUs' path="/ContactUs" component={ContactUs} />
                <Route exact pattern='/AboutUs' path="/AboutUs" component={AboutUs} />
                <Route exact pattern='/GameSetup' path="/GameSetup" component={GameSetup} />

                <Route exact pattern='/GameRules' path="/GameRules" component={GameRules} />
                <Route exact pattern='/RoleDescription' path="/RoleDescription" component={RoleDescription} />
                <Route exact pattern='/Support' path="/Support" component={Support} />
            </Router>
        </Provider>
    )
}

export default App;
