import React from "react";
import { connect } from 'react-redux'
import { storeCurrentUser } from '../store/actions'
import { Client } from "boardgame.io/react";
import { CollaborationGame } from "../gamePanel/gameBoardIO"
import { Local } from 'boardgame.io/multiplayer';
import { SocketIO } from 'boardgame.io/multiplayer'

import Board from "../gamePanel/board"
import GameLoading from '../adminPanel/GameLoading'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect';
// import { API_URL, SERVER_PORT, IS_PRODUCTION } from '../constants'

const GameContainer = Client({
    game: CollaborationGame,
    board: Board,
     loading: GameLoading,
     isLoading : true,
    numPlayers: 8,
    //  multiplayer: Local(),
    multiplayer: process.env.REACT_APP_STAGE == 'production' ?
        SocketIO({
            server: process.env.REACT_APP_API_URL, socketOpts: {
                path: '/server',
                transports: ['websocket']
            }
        })
        :
        SocketIO({
            server: `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_SERVER_PORT}`
        }),
    debug: false,
})

class gameObject extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <GameContainer
                    gameID={this.props.currentGameID}
                    credentials={this.props.currentUserCredentials}
                    playerID={this.props.currentUserPlayerID + ''}
                    childProps={this.props}
                />
            </DndProvider>

            
        )
    }
}

const mapStateToProps = state => ({
    currentUserFromStore: state.userReducer.currentUser,
    gameDataFromStore: state.gameReducer.gameData,

    currentUserPlayerID: state.gameReducer.currentUserPlayerID,
    currentUserCredentials: state.gameReducer.currentUserCredentials,
    currentGameID: state.gameReducer.currentGameID
})

const mapDispatchToProps = dispatch => ({
    storeCurrentUser: (currentUser) => { dispatch(storeCurrentUser(currentUser)) },
})



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(gameObject)